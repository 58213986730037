import React, { useState, useEffect } from "react";
import useFirebase from "../hooks/useFirebase";
import { useNavigate } from "react-router-dom";
import Input from "../components/Input";
import Button from "../components/Button";
import Lines from "../components/Lines";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import LoadingScreen from "../components/LoadingScreen";
import { useTranslation } from "react-i18next";
import hand from "../assets/images/peace_hand.png";

export default function Initialize() {
	const { t } = useTranslation();
	const { functions, user, httpsCallable, firestore, doc, getDoc } = useFirebase();
	const [username, setUsername] = useState("");
	const [available, setAvailable] = useState(true);
	const [loading, setLoading] = useState(false);
	const nav = useNavigate();

	useEffect(() => {
		checkuser();
		// eslint-disable-next-line
	}, [user]);

	async function checkuser() {
		if (user === null) {
			nav("/");
		}
	}

	const changeUsername = httpsCallable(functions, "changeUsername");

	const checkUsernameAvailability = async (checkusername) => {
		if (checkusername.length === 1) {
			setUsername(checkusername.replace(/[^0-9a-z_]/gi, "").substring(0, 30));
			const docRef = doc(firestore, "usernames", checkusername.toLowerCase());
			const docSnap = await getDoc(docRef);
			setAvailable(!docSnap.data());
		} else {
			setUsername(checkusername.replace(/[^0-9a-z_.@-]/gi, "").substring(0, 30));
			const docRef = doc(firestore, "usernames", checkusername.toLowerCase());
			const docSnap = await getDoc(docRef);
			setAvailable(!docSnap.data());
		}
	};

	const changeName = () => {
		setLoading(true);
		changeUsername({ username: username })
			.then((result) => {
				setLoading(false);
				// const data = result.data;
				// const sanitizedMessage = data.text;
				nav("/dashboard");
				// eslint-disable-next-line no-restricted-globals
				location.reload();
			})
			.catch((error) => {
				setLoading(false);
				console.log(error);
			});
	};

	return (
		<div className="w-full">
			<Navbar />
			<Lines />
			{/* <Input
				className="text-black"
				type="text"
				value={username}
				onChange={(e) => checkUsernameAvailability(e.target.value)}
				pattern="[a-zA-Z0-9!@$_.]{4,25}"
				label={t("initialize.username.input")}
			/>
			<p className="text-black">
				https://sharrin.gs/
				<span className={`${available ? "text-green-500" : "text-red-500"}`}>{username}</span>{" "}
				{available ? t("initialize.username.available") : t("initialize.username.given")}
			</p>
			<Button
				className={`${loading ? "bg-gray-800 text-gray-400" : "bg-dark-300"} cursor-pointer p-4`}
				disabled={loading}
				onClick={changeName}
			>
				{t("settings.save")}
			</Button>
			<LoadingScreen loading={loading} /> */}
			<div className="w-full p-8 pt-32 pb-20 md:px-20">
				<div className="relative mx-12 mb-40">
					<h1 className="text-[3rem] font-bold sm:ml-12 sm:text-[10vw] md:w-[50%] md:text-[8vw] lg:text-[6vw]">
						{t("initialize.pagetitle")}
					</h1>
					<img
						alt="Peace Hand"
						className="absolute right-[10%] top-[10%] -z-50 h-[324px] rotate-[30deg] sm:right-[20%] sm:-top-[20%] sm:h-[200%] md:max-h-[612px] lg:right-[5%]"
						src={hand}
					/>
				</div>

				<div className="flex flex-col gap-4 lg:ml-32 lg:w-[60%]">
					<h1 className="text-3xl sm:w-[60%]">{t("initialize.username.title")}</h1>
					<div>
						<Input
							className="text-black"
							type="text"
							value={username}
							onChange={(e) => checkUsernameAvailability(e.target.value)}
							pattern="[a-zA-Z0-9!@$_.]{4,25}"
							label={t("initialize.username.input")}
						/>
						<p className="text-black">
							https://sharrin.gs/
							<span className={`${available ? "text-green-500" : "text-red-500"}`}>{username}</span>{" "}
							{available ? t("initialize.username.available") : t("initialize.username.given")}
						</p>
					</div>
					<Button
						className={`${loading ? "bg-gray-800 text-gray-400" : "bg-dark-300"} cursor-pointer p-4`}
						disabled={loading}
						onClick={changeName}
					>
						{t("settings.save")}
					</Button>
				</div>
			</div>
			<Footer links={[{ text: "Art by icons8", url: "https://icons8.com/" }]} />
			<LoadingScreen loading={loading} />
		</div>
	);
}

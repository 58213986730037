import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useFirebase from "../hooks/useFirebase";
import Helmet from "react-helmet";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import SocialButton from "../components/SocialButton";
import Lines from "../components/Lines";
import LoadingScreen from "../components/LoadingScreen";

import { useTranslation } from "react-i18next";

import { GoogleAuthProvider, TwitterAuthProvider, FacebookAuthProvider, GithubAuthProvider } from "@firebase/auth";

const googleProvider = new GoogleAuthProvider();
const twitterProvider = new TwitterAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const githubProvider = new GithubAuthProvider();

function Login() {
	const { t } = useTranslation();
	const { auth, signInWithPopup } = useFirebase();
	const [loading, setLoading] = useState(false);
	const nav = useNavigate();

	const loginWithGoogle = () => {
		signInWithPopup(auth, googleProvider).then((result) => {
			if (result.user) {
				nav("/dashboard");
			}
		});
	};

	const loginWithTwitter = () => {
		signInWithPopup(auth, twitterProvider).then((result) => {
			if (result.user) {
				nav("/dashboard");
			}
		});
	};

	const loginWithFacebook = () => {
		signInWithPopup(auth, facebookProvider).then((result) => {
			if (result.user) {
				nav("/dashboard");
			}
		});
	};

	const loginWithGithub = () => {
		signInWithPopup(auth, githubProvider).then((result) => {
			if (result.user) {
				nav("/dashboard");
			}
		});
	};

	return (
		<div>
			<Helmet>
				<title>{t("login.title")}</title>
			</Helmet>
			<Navbar centerElements={<p className="text-2xl font-bold">{t("login.pagetitle")}</p>} />
			<div className="flex h-[calc(100vh-13rem)] w-full flex-col items-center justify-center gap-4 px-4 md:px-12">
				<SocialButton onClick={loginWithGoogle}>
					<img className="w-8" alt="Google" src={`/logos/google.svg`} />
					<p>Sign in with Google</p>
				</SocialButton>
				<SocialButton onClick={loginWithTwitter}>
					<img className="w-8" alt="Twitter" src={`/logos/twitter.svg`} />
					<p>Sign in with Twitter</p>
				</SocialButton>
				<SocialButton onClick={loginWithFacebook}>
					<img className="w-8" alt="Facebook" src={`/logos/facebook.svg`} />
					<p>Sign in with Facebook</p>
				</SocialButton>
				<SocialButton onClick={loginWithGithub}>
					<img className="w-8" alt="Github" src={`/logos/github.svg`} />
					<p>Sign in with Github</p>
				</SocialButton>
			</div>
			<Lines />
			<Footer />
			<LoadingScreen loading={loading} />
		</div>
	);
}
export default Login;

import { useState } from "react";
// import Button from "../components/Button";

function Input(props) {
	let {
		type = "text",
		onChange = () => {},
		onFocusChange = (e) => {},
		onClick = () => {},
		min,
		max,
		label,
		className = "",
		step,
		accept,
		value = type === "range" ? 0 : "",
	} = props;

	const [focused, setFocused] = useState(false);
	const empty = value.length === 0;
	const underline = type !== "range" && type !== "file";
	const hoverLabel = !underline ? true : focused || !empty;

	const focusChange = (focusState) => {
		setFocused(focusState);
		onFocusChange({ state: focusState });
	};
	return (
		<div className={`${className} flex flex-col items-start ${type === "file" ? "mt-8" : "mt-0"}`}>
			<div
				className={`relative w-full ${
					underline &&
					"before:absolute before:bottom-0 before:h-[1px] before:w-full before:bg-accent-200 before:content-['']"
				}`}
			>
				<p
					className={`${
						hoverLabel
							? type === "range"
								? "-translate-y-2 translate-x-2 text-gray-500"
								: "-translate-y-6 translate-x-2 text-gray-500"
							: "translate-x-4 translate-y-2"
					} pointer-events-none absolute select-none duration-300`}
				>
					{label}
				</p>
				<div className="w-full">
					<input
						type={type}
						className={`${className} ${
							type === "file"
								? "form-control m-0 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-accent-200 focus:bg-white focus:text-gray-700 focus:outline-none"
								: type === "text"
								? "shadow-inset py-2 px-4 accent-accent-200 outline-0"
								: type === "range"
								? "shadow-inset mt-4 accent-accent-200 outline-0"
								: "shadow-inset accent-accent-200 outline-0"
						} w-full`}
						min={min}
						max={max}
						step={step}
						accept={accept}
						value={value}
						onChange={(e) => {
							onChange(e);
						}}
						onClick={(e) => {
							onClick(e);
						}}
						onFocus={(e) => {
							focusChange(true);
						}}
						onBlur={(e) => {
							focusChange(false);
						}}
					/>
				</div>
			</div>
		</div>
	);
}
export default Input;

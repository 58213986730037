import React from "react";

function Line1({ className, stroke = "var(--accent-200)", width = 4 }) {
	return (
		<div className={className}>
			<svg width="1920" height="1080" viewBox="0 0 1920 1080" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="transparent"
					stroke={stroke}
					strokeWidth={width}
					d="M1011.5-27.5c-16.12,9.76-85.69,54.3-111,145-17.26,61.85-15.14,149.46,34,204,97.13,107.82,303.67,3.95,343,76,32.21,59-70.2,194.87-180,276C950.62,782,811.64,777,805.5,839.5c-6.22,63.35,133.67,98.49,131,166-2.42,61.08-119.91,108.16-243,142"
				/>
			</svg>
		</div>
	);
}

function Line2({ className, stroke = "var(--accent-200)", width = 4 }) {
	return (
		<div className={className}>
			<svg width="1920" height="1080" viewBox="0 0 1920 1080" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="transparent"
					stroke={stroke}
					strokeWidth={width}
					d="M1946.5,127.5c-200.16,139-357.9,160.43-464,155-127.91-6.55-162-51.24-353-83-139-23.13-429.21-50.7-463,36-32.39,83.12,204.58,184.73,180,310-20.74,105.66-205.19,114.11-424,237-186.34,104.65-309.81,243-387,348"
				/>
			</svg>
		</div>
	);
}

function Line3({ className, stroke = "var(--accent-200)", width = 4 }) {
	return (
		<div className={className}>
			<svg width="1920" height="1080" viewBox="0 0 1920 1080" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="transparent"
					stroke={stroke}
					strokeWidth={width}
					d="M-24.5,101.5c15.66-9.8,161.67-97.82,321-32,89.57,37,195,127.42,196,233,1.29,132.26-162.38,171.11-154,270,11.11,131.13,318.06,289.12,518,199,208.84-94.13,158.55-394.79,345-450,177-52.41,381.87,171.21,422,215,201.6,220,211.6,493.33,211,583"
				/>
			</svg>
		</div>
	);
}

export { Line1, Line2, Line3 };

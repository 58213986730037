import React, { useEffect, useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import Divider from "../components/Divider";
import LanguagePicker from "../components/LanguagePicker";
import { Popover } from "@headlessui/react";
import { HomeIcon, CogIcon, LogoutIcon } from "@heroicons/react/outline";

import useFirebase from "../hooks/useFirebase";

import { useTranslation } from "react-i18next";

function Navbar({ centerElements, linkElements }) {
	const { t } = useTranslation();
	const { auth, user, signOut, signInWithRedirect, provider, profilePictureUrl } = useFirebase();
	const [loggedIn, setLoggedIn] = useState(false);
	const nav = useNavigate();

	const logout = () => {
		signOut(auth);
	};

	useEffect(() => {
		if (user) {
			setLoggedIn(true);
		} else {
			setLoggedIn(false);
		}
	}, [user]);

	return (
		<div className="fixed top-0 z-50 flex w-full justify-between overflow-y-visible border-b-[1px] border-gray-300 bg-white bg-opacity-80 py-4 px-4 shadow-md backdrop-blur-sm md:px-20">
			{/* <div className="scrolled fixed top-0 z-10 flex w-full items-center justify-between border-b-[1px] border-gray-200 px-12 md:justify-around md:px-0"> */}
			<button onClick={() => nav("/")} className="flex cursor-pointer items-center gap-2 text-xl font-bold">
				<img className="h-12 w-12" src="logo.svg" alt="sharrings logo"></img>
				<p className="hidden select-none text-2xl md:block">Sharrings</p>
			</button>
			<div className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 items-center gap-2">
				{centerElements && <>{centerElements}</>}
			</div>
			<div className="flex items-center gap-4">
				{!linkElements ? (
					<>
						{!loggedIn ? (
							<>
								<button
									className="color cursor-pointer no-underline"
									onClick={() => {
										signInWithRedirect(auth, provider).then((result) => {
											if (result.user) {
												nav("/dashboard");
											}
										});
									}}
								>
									Login
								</button>
								<div>
									<Button
										onClick={() => {
											signInWithRedirect(auth, provider).then((result) => {
												if (result.user) {
													nav("/dashboard");
												}
											});
										}}
									>
										Sign up
									</Button>
								</div>
							</>
						) : (
							<div className="flex items-center gap-4">
								<LanguagePicker />
								<Popover className="relative h-10 w-10">
									{({ open }) => (
										<>
											<Popover.Button>
												<img
													src={profilePictureUrl}
													alt="profile"
													className="cursor-pointer rounded-full border-2 border-accent-200"
												></img>
											</Popover.Button>
											<Popover.Panel className="absolute left-12 top-full z-20 mt-1 -translate-x-full transform px-0">
												<div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
													<div className="flex flex-col bg-white px-4 py-3">
														<div
															className="flex cursor-pointer flex-row items-center justify-start gap-2 rounded-md border-[1px] border-transparent py-1 px-2 hover:border-gray-100 hover:bg-gray-50"
															onClick={() => nav("/dashboard")}
														>
															<HomeIcon className="h-5 w-5" />
															<span className="text-lg">{t("navbar.dashboard")}</span>
														</div>
														<Divider />
														<div
															className="flex cursor-pointer flex-row items-center justify-start gap-2 rounded-md border-[1px] border-transparent py-1 px-2 hover:border-gray-100 hover:bg-gray-50"
															onClick={() => nav("/settings")}
														>
															<CogIcon className="h-5 w-5" />
															<span className="text-lg">{t("navbar.settings")}</span>
														</div>
														<div
															className="flex cursor-pointer flex-row items-center justify-start gap-2 rounded-md border-[1px] border-gray-300 bg-gray-100 py-1 px-2 hover:bg-gray-300"
															onClick={() => {
																logout();
															}}
														>
															<LogoutIcon className="h-5 w-5" />
															<span className="text-lg">{t("navbar.logout")}</span>
														</div>
													</div>
												</div>
											</Popover.Panel>
										</>
									)}
								</Popover>
							</div>
						)}
					</>
				) : (
					<>
						{!loggedIn ? (
							<div className="flex flex-row items-center gap-4">
								<LanguagePicker />
								<Button
									onClick={() => {
										nav("/login");
									}}
								>
									{t("navbar.signup")}
								</Button>
							</div>
						) : (
							<>{linkElements}</>
						)}
					</>
				)}
			</div>
		</div>
	);
}

export default Navbar;

import React from "react";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function PrivacyPolicy() {
	return (
		<div>
			<Navbar centerElements={<p className="text-2xl font-bold">Privacy Policy</p>} />
			<div>
				<div className="flex h-full w-full flex-col items-center justify-center py-20">
					<div className="w-1/2">
						<div className="flex flex-col gap-4">
							<p className="text-3xl">Privacy policy</p>
							<p className="text-2xl">1. Data protection at a glance</p>
							<p className="text-xl">General notes</p>{" "}
							<p>
								The following notices provide a simple overview of what happens to your personal data when you visit
								this website. Personal data is any data that can be used to identify you personally. For detailed
								information on the subject of data protection, please refer to our privacy policy listed below this
								text.
							</p>
							<p className="text-xl">Data collection on this website</p>{" "}
							<h4>Who is responsible for the data collection on this website?</h4>{" "}
							<p>
								The data processing on this website is carried out by the website operator. You can find the contact
								details of the website operator in the section "Information about the responsible party" in this data
								protection declaration.
							</p>{" "}
							<h4>How do we collect your data?</h4>{" "}
							<p>
								On the one hand, your data is collected by you providing it to us. This can be, for example, data that
								you enter in a contact form.
							</p>{" "}
							<p>
								Other data is collected automatically or after your consent when you visit the website by our IT
								systems. This is mainly technical data (e.g. internet browser, operating system or time of page view).
								The collection of this data takes place automatically as soon as you enter this website.
							</p>{" "}
							<h4>What do we use your data for?</h4>{" "}
							<p>
								Some of the data is collected to ensure error-free provision of the website. Other data may be used to
								analyze your user behavior.
							</p>{" "}
							<h4>What rights do you have regarding your data?</h4>{" "}
							<p>
								You have the right at any time to receive information free of charge about the origin, recipient and
								purpose of your stored personal data. You also have a right to request the correction or deletion of
								this data. If you have given your consent to data processing, you can revoke this consent at any time
								for the future. You also have the right to request the restriction of the processing of your personal
								data under certain circumstances. Furthermore, you have the right to lodge a complaint with the
								competent supervisory authority.
							</p>{" "}
							<p>
								You can contact us at any time with regard to this and other questions on the subject of data
								protection.
							</p>
							<p className="text-xl">Third-party analytics and tools</p>{" "}
							<p>
								When visiting this website, your surfing behavior may be statistically analyzed. This is done mainly
								with so-called analysis programs.
							</p>{" "}
							<p>Detailed information about these analysis programs can be found in the following privacy policy.</p>
							<p className="text-2xl">2. Hosting</p>
							<p className="text-xl">External hosting</p>{" "}
							<p>
								This website is hosted by an external service provider (hoster). The personal data collected on this
								website is stored on the hoster's servers. This may include IP addresses, contact requests, meta and
								communication data, contract data, contact data, names, website accesses and other data generated via a
								website.
							</p>{" "}
							<p>
								The hoster is used for the purpose of fulfilling the contract with our potential and existing customers
								(Art. 6 para. 1 lit. b DSGVO) and in the interest of a secure, fast and efficient provision of our
								online offer by a professional provider (Art. 6 para. 1 lit. f DSGVO). Insofar as a corresponding
								consent has been requested, the processing is carried out exclusively on the basis of Art. 6 para. 1
								lit. a DSGVO and § 25 para. 1 TTDSG, insofar as the consent includes the storage of cookies or access to
								information in the user's terminal device (e.g. device fingerprinting) as defined by the TTDSG. The
								consent can be revoked at any time.
							</p>{" "}
							<p>
								Our hoster will only process your data to the extent necessary to fulfill its service obligations and
								will follow our instructions regarding this data.
							</p>{" "}
							<p>We use the following hoster::</p>
							<p>
								Cloudflare Germany GmbH
								<br />
								Rosental 7<br />
								80331 M&uuml;nchen
								<br />
								Attention: Data Protection Officer
								<br />
								privacyquestions@cloudflare.com
							</p>
							<p className="text-2xl">3. general notes and mandatory information</p>
							<p className="text-xl">Privacy</p>{" "}
							<p>
								The operators of these pages take the protection of your personal data very seriously. We treat your
								personal data confidentially and in accordance with the statutory data protection regulations and this
								privacy policy.
							</p>{" "}
							<p>
								When you use this website, various personal data are collected. Personal data is data with which you can
								be personally identified. This privacy policy explains what data we collect and what we use it for. It
								also explains how and for what purpose this is done.
							</p>{" "}
							<p>
								We point out that data transmission over the Internet (eg communication by e-mail) security gaps. A
								complete protection of the data against access by third parties is not possible.
							</p>
							<p className="text-xl">Note on the responsible entity</p>{" "}
							<p>The responsible party for data processing on this website is:</p>{" "}
							<p>
								Christopher Brandt
								<br />
								Am Dorfplatz, 6<br />
								76835 Hainfeld
								<br />
								Germany
							</p>
							<p>
								Phone: +491703537300
								<br />
								E-Mail: kostariprints@gmail.com
							</p>
							<p>
								The controller is the natural or legal person who alone or jointly with others determines the purposes
								and means of the processing of personal data (e.g. names, e-mail addresses, etc.).
							</p>
							<p className="text-xl">Storage duration</p>{" "}
							<p>
								Unless a more specific storage period has been specified within this privacy policy, your personal data
								will remain with us until the purpose for data processing no longer applies. If you assert a legitimate
								request for deletion or revoke your consent to data processing, your data will be deleted unless we have
								other legally permissible reasons for storing your personal data (e.g. retention periods under tax or
								commercial law); in the latter case, the data will be deleted once these reasons no longer apply.
							</p>
							<p className="text-xl">General information on the legal basis for data processing on this website</p>{" "}
							<p>
								If you have consented to data processing, we process your personal data on the basis of Art. 6 (1) a
								DSGVO or Art. 9 (2) a DSGVO, if special categories of data are processed according to Art. 9 (1) DSGVO.
								In the case of explicit consent to the transfer of personal data to third countries, the data processing
								is also based on Art. 49 (1) a DSGVO. If you have consented to the storage of cookies or to the access
								to information in your terminal device (e.g. via device fingerprinting), the data processing is
								additionally carried out on the basis of Section 25 (1) TTDSG. The consent can be revoked at any time.
								If your data is required for the performance of a contract or for the implementation of pre-contractual
								measures, we process your data on the basis of Art. 6 para. 1 lit. b DSGVO. Furthermore, if your data is
								required for the fulfillment of a legal obligation, we process it on the basis of Art. 6 para. 1 lit. c
								DSGVO. Furthermore, the data processing may be carried out on the basis of our legitimate interest
								according to Art. 6 para. 1 lit. f DSGVO. Information about the relevant legal basis in each individual
								case is provided in the following paragraphs of this privacy policy.
							</p>
							<p className="text-xl">Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten</p>{" "}
							<p>
								Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den USA oder sonstigen
								datenschutzrechtlich nicht sicheren Drittstaaten. Wenn diese Tools aktiv sind, k&ouml;nnen Ihre
								personenbezogene Daten in diese Drittstaaten &uuml;bertragen und dort verarbeitet werden. Wir weisen
								darauf hin, dass in diesen L&auml;ndern kein mit der EU vergleichbares Datenschutzniveau garantiert
								werden kann. Beispielsweise sind US-Unternehmen dazu verpflichtet, personenbezogene Daten an
								Sicherheitsbeh&ouml;rden herauszugeben, ohne dass Sie als Betroffener hiergegen gerichtlich vorgehen
								k&ouml;nnten. Es kann daher nicht ausgeschlossen werden, dass US-Beh&ouml;rden (z.&nbsp;B.
								Geheimdienste) Ihre auf US-Servern befindlichen Daten zu &Uuml;berwachungszwecken verarbeiten, auswerten
								und dauerhaft speichern. Wir haben auf diese Verarbeitungst&auml;tigkeiten keinen Einfluss.
							</p>
							<p className="text-xl">Note on data transfer to the USA and other third countries</p>{" "}
							<p>
								Among other things, we use tools from companies based in the USA or other third countries that are not
								secure under data protection law. If these tools are active, your personal data may be transferred to
								these third countries and processed there. We would like to point out that no level of data protection
								comparable to that in the EU can be guaranteed in these countries. For example, US companies are obliged
								to hand over personal data to security authorities without you as a data subject being able to take
								legal action against this. It can therefore not be ruled out that US authorities (e.g. intelligence
								services) process, evaluate and permanently store your data located on US servers for monitoring
								purposes. We have no influence on these processing activities.
							</p>
							<p className="text-xl">
								Right to object to data collection in special cases and to direct marketing (Art. 21 DSGVO)
							</p>{" "}
							<p>
								IF THE DATA PROCESSING IS CARRIED OUT ON THE BASIS OF ART. 6 ABS. 1 LIT. E OR F DSGVO, YOU HAVE THE
								RIGHT TO OBJECT TO THE PROCESSING OF YOUR PERSONAL DATA AT ANY TIME FOR REASONS ARISING FROM YOUR
								PARTICULAR SITUATION; THIS ALSO APPLIES TO PROFILING BASED ON THESE PROVISIONS. THE RESPECTIVE LEGAL
								BASIS ON WHICH PROCESSING IS BASED CAN BE FOUND IN THIS PRIVACY POLICY. IF YOU OBJECT, WE WILL NO LONGER
								PROCESS YOUR PERSONAL DATA UNLESS WE CAN DEMONSTRATE COMPELLING LEGITIMATE GROUNDS FOR THE PROCESSING
								WHICH OVERRIDE YOUR INTERESTS, RIGHTS AND FREEDOMS, OR THE PROCESSING SERVES THE PURPOSE OF ASSERTING,
								EXERCISING OR DEFENDING LEGAL CLAIMS (OBJECTION UNDER ARTICLE 21 (1) DSGVO).
							</p>{" "}
							<p>
								IF YOUR PERSONAL DATA IS PROCESSED FOR THE PURPOSE OF DIRECT MARKETING, YOU HAVE THE RIGHT TO OBJECT AT
								ANY TIME TO THE PROCESSING OF PERSONAL DATA CONCERNING YOU FOR THE PURPOSE OF SUCH MARKETING; THIS ALSO
								APPLIES TO PROFILING INSOFAR AS IT IS CONNECTED WITH SUCH DIRECT MARKETING. IF YOU OBJECT, YOUR PERSONAL
								DATA WILL SUBSEQUENTLY NO LONGER BE USED FOR THE PURPOSE OF DIRECT MARKETING (OBJECTION PURSUANT TO
								ARTICLE 21 (2) DSGVO).
							</p>
							<p className="text-xl">Right of appeal to the competent supervisory authority</p>{" "}
							<p>
								In the event of breaches of the GDPR, data subjects shall have a right of appeal to a supervisory
								authority, in particular in the Member State of their habitual residence, their place of work or the
								place of the alleged breach. The right of appeal is without prejudice to other administrative or
								judicial remedies.
							</p>
							<p className="text-xl">Right to data portability</p>{" "}
							<p>
								You have the right to have data that we process automatically on the basis of your consent or in
								fulfillment of a contract handed over to you or to a third party in a common, machine-readable format.
								If you request the direct transfer of the data to another controller, this will only be done insofar as
								it is technically feasible.
							</p>
							<p className="text-xl">SSL or TLS encryption</p>{" "}
							<p>
								This site uses SSL or TLS encryption for security reasons and to protect the transmission of
								confidential content, such as orders or requests that you send to us as the site operator. You can
								recognize an encrypted connection by the fact that the address line of the browser changes from
								"http://" to "https://" and by the lock symbol in your browser line. If SSL or TLS encryption is
								activated, the data you transmit to us cannot be read by third parties.
							</p>{" "}
							<p>
								Wenn die SSL- bzw. TLS-Verschl&uuml;sselung aktiviert ist, k&ouml;nnen die Daten, die Sie an uns
								&uuml;bermitteln, nicht von Dritten mitgelesen werden.
							</p>
							<p className="text-xl">Information, deletion and correction</p>{" "}
							<p>
								Within the framework of the applicable legal provisions, you have the right at any time to free
								information about your stored personal data, its origin and recipient and the purpose of data processing
								and, if necessary, a right to correction or deletion of this data. For this purpose, as well as for
								further questions on the subject of personal data, you can contact us at any time.
							</p>
							<p className="text-xl">Right to restriction of processing</p>{" "}
							<p>
								You have the right to request the restriction of the processing of your personal data. For this purpose,
								you can contact us at any time. The right to restriction of processing exists in the following cases:
							</p>{" "}
							<ul>
								{" "}
								<li>
									If you dispute the accuracy of your personal data stored by us, we usually need time to verify this.
									For the duration of the review, you have the right to request the restriction of the processing of
									your personal data.
								</li>{" "}
								<li>
									If the processing of your personal data happened/is happening unlawfully, you may request the
									restriction of data processing instead of erasure.
								</li>{" "}
								<li>
									If we no longer need your personal data, but you need it to exercise, defend or enforce legal claims,
									you have the right to request restriction of the processing of your personal data instead of deletion.
								</li>{" "}
								<li>
									be made. As long as it has not yet been determined whose interests prevail, you have the right to
									demand the restriction of the processing of your personal data.
								</li>{" "}
							</ul>{" "}
							<p>
								If you have restricted the processing of your personal data, this data may - apart from being stored -
								only be processed with your consent or for the assertion, exercise or defense of legal claims or for the
								protection of the rights of another natural or legal person or for reasons of an important public
								interest of the European Union or a Member State.
							</p>
							<p className="text-2xl">4. data collection on this website</p>
							<p className="text-xl">Cookies</p>{" "}
							<p>
								Our Internet pages use so-called "cookies". Cookies are small text files and do not cause any damage to
								your terminal device. They are stored either temporarily for the duration of a session (session cookies)
								or permanently (permanent cookies) on your end device. Session cookies are automatically deleted at the
								end of your visit. Permanent cookies remain stored on your end device until you delete them yourself or
								until they are automatically deleted by your web browser.
							</p>{" "}
							<p>
								In some cases, cookies from third-party companies may also be stored on your terminal device when you
								enter our site (third-party cookies). These enable us or you to use certain services of the third-party
								company (e.g. cookies for processing payment services).
							</p>{" "}
							<p>
								Cookies have various functions. Many cookies are technically necessary, as certain website functions
								would not work without them (e.g. the shopping cart function or the display of videos). Other cookies
								are used to evaluate user behavior or display advertising.
							</p>{" "}
							<p>
								Cookies that are necessary to carry out the electronic communication process, to provide certain
								functions that you have requested (e.g. for the shopping cart function) or to optimize the website (e.g.
								cookies to measure the web audience) (necessary cookies) are stored on the basis of Art. 6 (1) lit. f
								DSGVO, unless another legal basis is specified. The website operator has a legitimate interest in
								storing necessary cookies for the technically error-free and optimized provision of its services. If
								consent to the storage of cookies and comparable recognition technologies has been requested, the
								processing is based exclusively on this consent (Art. 6 para. 1 lit. a DSGVO and § 25 para. 1 TTDSG);
								the consent can be revoked at any time.
							</p>{" "}
							<p>
								You can set your browser so that you are informed about the setting of cookies and only allow cookies in
								individual cases, exclude the acceptance of cookies for certain cases or in general and activate the
								automatic deletion of cookies when closing the browser. When deactivating cookies, the functionality of
								this website may be limited.
							</p>{" "}
							<p>
								If cookies are used by third-party companies or for analysis purposes, we will inform you about this
								separately within the framework of this data protection declaration and, if necessary, request your
								consent.
							</p>
							<p className="text-2xl">5. Plugins and Tools</p>
							<p className="text-xl">Google Web Fonts (local Hosting)</p>{" "}
							<p>
								This site uses so-called web fonts provided by Google for the uniform display of fonts. The Google Fonts
								are installed locally. A connection to Google servers does not take place.
							</p>{" "}
							<p>
								For more information about Google Web Fonts, see{" "}
								<a href="https://developers.google.com/fonts/faq" target="_blank" rel="noopener noreferrer">
									https://developers.google.com/fonts/faq
								</a>{" "}
								and in the privacy policy of Google:{" "}
								<a href="https://policies.google.com/privacy?hl=de" target="_blank" rel="noopener noreferrer">
									https://policies.google.com/privacy?hl=de
								</a>
								.
							</p>
							<p className="text-xl">Google reCAPTCHA</p>{" "}
							<p>
								We use "Google reCAPTCHA" (hereinafter "reCAPTCHA") on this website. The provider is Google Ireland
								Limited ("Google"), Gordon House, Barrow Street, Dublin 4, Ireland.
							</p>{" "}
							<p>
								The purpose of reCAPTCHA is to check whether the data input on this website (e.g. in a contact form) is
								made by a human or by an automated program. For this purpose, reCAPTCHA analyzes the behavior of the
								website visitor based on various characteristics. This analysis begins automatically as soon as the
								website visitor enters the website. For the analysis, reCAPTCHA evaluates various information (e.g. IP
								address, time spent by the website visitor on the website or mouse movements made by the user). The data
								collected during the analysis is forwarded to Google.
							</p>{" "}
							<p>
								The reCAPTCHA analyses run completely in the background. Website visitors are not notified that an
								analysis is taking place.
							</p>{" "}
							<p>
								The storage and analysis of the data is based on Art. 6 para. 1 lit. f DSGVO. The website operator has a
								legitimate interest in protecting its web offers from abusive automated spying and from SPAM. If a
								corresponding consent has been requested, the processing is carried out exclusively on the basis of Art.
								6 para. 1 lit. a DSGVO and § 25 para. 1 TTDSG, insofar as the consent includes the storage of cookies or
								access to information in the user's terminal device (e.g. device fingerprinting) as defined by the
								TTDSG. The consent can be revoked at any time.
							</p>{" "}
							<p>
								For more information about Google reCAPTCHA, please see the Google Privacy Policy and the Google Terms
								of Service at the following links:{" "}
								<a href="https://policies.google.com/privacy?hl=de" target="_blank" rel="noopener noreferrer">
									https://policies.google.com/privacy?hl=de
								</a>{" "}
								und{" "}
								<a href="https://policies.google.com/terms?hl=de" target="_blank" rel="noopener noreferrer">
									https://policies.google.com/terms?hl=de
								</a>
								.
							</p>
							<p>
								Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a>
							</p>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</div>
	);
}

export default PrivacyPolicy;

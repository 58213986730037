import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import useFirebase from "../hooks/useFirebase";
import Helmet from "react-helmet";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Input from "../components/Input";
import Lines from "../components/Lines";
import SocialButton from "../components/SocialButton";
import SocialsAnimation from "../components/SocialsAnimation";
import LanguagePicker from "../components/LanguagePicker";

import { calculateColorPalette } from "../assets/ColorCalculation";

import renderedLogo from "../assets/images/logo-render.webp";
import Button from "../components/Button";

import { useTranslation } from "react-i18next";
import Divider from "../components/Divider";

function Home() {
	const { t } = useTranslation();
	const nav = useNavigate();
	useEffect(() => {
		calculateColorPalette("var(--accent-default)");
	}, []);

	return (
		<div>
			<Helmet>
				<title>{t("landing.title")}</title>
			</Helmet>
			<Navbar
				linkElements={
					<div className="flex flex-row items-center gap-4">
						<LanguagePicker />
						<Button onClick={() => nav("dashboard")}>Dashboard</Button>
					</div>
				}
			/>
			<Lines />
			<div className="mt-36 flex flex-col gap-16 sm:gap-36 lg:gap-48">
				<Header2 />
				<AllSocialsAnimated />
				<EasilyManaged />
				<SharringsCommunity />
				<Footer />
			</div>
		</div>
	);
}

const Header = () => {
	const { t } = useTranslation();

	const [inputUsername, setInputUsername] = useState("");
	const [inputUsernameLabel, setInputUsernameLabel] = useState(t("landing.calltosignup.input"));

	return (
		<Section className="flex flex-col items-center justify-center gap-12">
			<motion.div
				initial={{
					x: -40,
					opacity: 0,
				}}
				animate={{
					x: 0,
					opacity: 1,
				}}
				transition={{
					duration: 0.6,
				}}
			>
				<h1 className="text-[12vw] font-bold md:text-[6rem]">
					Sharrin
					<motion.span
						initial={{ color: "#000" }}
						animate={{ color: "var(--accent-default)" }}
						transition={{ duration: 0.3, delay: 0.6 }}
						className="text-accent-200"
					>
						gs
					</motion.span>
				</h1>
				<p className="text-lg font-semibold">
					{t("landing.slogan1")}{" "}
					<span className="relative after:absolute after:top-[.25rem] after:left-2 after:-z-10 after:h-[100%] after:w-[100%] after:scale-110 after:bg-brush after:bg-contain after:bg-no-repeat after:content-['']">
						{t("landing.slogan2")}
					</span>
				</p>
			</motion.div>
			<motion.div
				initial={{ y: 40, opacity: 0 }}
				animate={{ y: 0, opacity: 1 }}
				transition={{ duration: 0.6, delay: 0.3 }}
				className="flex w-full flex-col items-center justify-evenly gap-12 sm:w-[90%] md:w-[60%] md:flex-row"
			>
				<img
					alt="3d render of sharrings logo"
					className="aspect-auto h-full w-full max-w-[16rem] drop-shadow-md md:max-w-xs"
					src={renderedLogo}
					// src="profile_example_iphone.png"
				/>
				<div className="flex flex-col justify-center gap-8">
					<p className="text-xl">{t("landing.calltosignup.text")}</p>
					<div className="flex items-center justify-center">
						<Input
							className="w-full"
							label={
								<span className="text-black">
									sharrin.gs/
									<span className="text-gray-500">{inputUsernameLabel}</span>
								</span>
							}
							onChange={(e) => {
								const val = e.target.value;
								setInputUsername(val);
								setInputUsernameLabel(val.length === 0 ? t("landing.calltosignup.input") : val);
							}}
							value={inputUsername}
						/>
						<Button className="rounded-l-none">{t("landing.calltosignup.button")}</Button>
					</div>
				</div>
			</motion.div>
		</Section>
	);
};

const Header2 = () => {
	const { t } = useTranslation();
	const { userProfile } = useFirebase();
	const [iframeLoaded, setIframeLoaded] = useState(false);

	// useEffect(() => {
	// 	var iframe = document.getElementById("myFrame");
	// 	var elmnt = iframe.contentWindow.document.getElementById("cookiepopup");
	// 	var elmnt2 = iframe.contentWindow.document.getElementById("landing-button");
	// 	if (elmnt) {
	// 		elmnt.style.display = "none";
	// 		elmnt2.style.display = "none";
	// 	}
	// }, [iframeLoaded]);

	return (
		<Section className="flex flex-col items-center gap-24">
			<motion.div
				initial={{
					x: -40,
					opacity: 0,
				}}
				animate={{
					x: 0,
					opacity: 1,
				}}
				transition={{
					duration: 0.6,
				}}
				className="flex flex-row items-center justify-center gap-8"
			>
				{/* Logo */}
				<div className="hidden md:flex">
					<img
						alt="3d render of sharrings logo"
						className="aspect-auto h-full w-full max-w-[11rem] drop-shadow-md lg:max-w-[13rem]"
						src={renderedLogo}
						// src="profile_example_iphone.png"
					/>
				</div>
				{/* Text */}
				<div>
					<div>
						<h1 className="text-[18vw] font-bold md:text-9xl lg:text-[9rem]">
							Sharrin
							<motion.span
								initial={{ color: "#000" }}
								animate={{ color: "var(--accent-default)" }}
								transition={{ duration: 0.3, delay: 0.6 }}
								className="text-accent-200"
							>
								gs
							</motion.span>
						</h1>
						<p className="text-base font-semibold md:text-lg lg:text-xl">
							{t("landing.slogan1")}{" "}
							<span className="relative after:absolute after:top-[.25rem] after:left-2 after:-z-10 after:h-[100%] after:w-[100%] after:scale-110 after:bg-brush after:bg-contain after:bg-no-repeat after:content-['']">
								{t("landing.slogan2")}
							</span>
						</p>
					</div>
				</div>
			</motion.div>
			<Divider className="w-1/3" />
			<div className="flex flex-col items-center">
				{/* Text */}
				<div className="w-5/6 max-w-3xl text-center text-2xl text-gray-600 sm:w-full md:w-3/5 md:max-w-none">
					<span>{t("landing.calltosignup.text")}</span>
				</div>
				{/* Profile Example Image */}
				<div className="flex h-[737px] w-[390px] scale-75 items-center justify-center sm:scale-100">
					<img src="profile_example_iphone.png" alt="Profile Example" className="absolute w-[390px]" />
					{/* <img src="iphone.png" alt="Profile Example" className="absolute w-[390px]" />
					<iframe
						id="myFrame"
						src={
							userProfile?.data()
								? `${window.location.href}${userProfile.data()?.username}`
								: `${window.location.href}tim.allen`
						}
						className="h-[660px] rounded-2xl md:w-[300px]"
						scrolling="no"
						onLoad={() => setIframeLoaded(true)}
					/> */}
				</div>
			</div>
		</Section>
	);
};

const AllSocialsAnimated = () => {
	const { t } = useTranslation();
	return (
		<Section>
			<motion.div
				initial={{
					y: 50,
					opacity: 0,
				}}
				animate={{
					y: 0,
					opacity: 1,
				}}
				transition={{
					duration: 0.6,
					delay: 0.4,
				}}
				className="flex w-full flex-col items-center justify-center md:flex-row"
			>
				{/* <div className="flex w-full max-w-3xl flex-col text-center md:w-[40%] md:text-right">
					<span className="text-semibold text-5xl">{t("landing.socialsanimation.title")}</span>
					<span className="text-2xl">{t("landing.socialsanimation.text")}</span>
				</div> */}
				{/* Text */}
				<div className="flex w-full max-w-3xl flex-col gap-4 text-center md:w-4/12 md:text-left">
					{/* Title */}
					<div>
						<span className="text-5xl text-neutral-800">{t("landing.socialsanimation.title")}</span>
					</div>
					{/* Text */}
					<div>
						<span className="text-2xl text-neutral-600">{t("landing.socialsanimation.text")}</span>
					</div>
				</div>
				<div className="p-[64px]">
					<SocialsAnimation />
				</div>
			</motion.div>
		</Section>
	);
};

const EasilyManaged = () => {
	const { t } = useTranslation();

	return (
		<Section>
			<div className="flex w-full flex-col-reverse items-center justify-center gap-8 md:flex-row">
				{/* Graphic */}
				<div>
					<img src="dashboard_example_iphone.png" alt="Dashboard Example" className="w-60" />
				</div>
				{/* Text */}
				<div className="flex w-full max-w-3xl flex-col gap-4 text-center md:w-4/12 md:text-left">
					{/* Title */}
					<div>
						<span className="text-semibold text-5xl text-neutral-800">{t("landing.easymanaged.title")}</span>
					</div>
					{/* Text */}
					<div>
						<span className="text-2xl text-neutral-600">{t("landing.easymanaged.text")}</span>
					</div>
				</div>
			</div>
		</Section>
	);
};

const SharringsCommunity = () => {
	const { t } = useTranslation();

	return (
		<Section className={"flex flex-col items-center gap-8"}>
			{/* Text */}
			<div className="text-center text-5xl">
				<span>{t("landing.community.text")}</span>
			</div>
			{/* Buttons */}
			<div className="flex flex-col gap-4 md:flex-row">
				<div className="flex cursor-pointer flex-col items-center justify-center gap-4 rounded-2xl border-2 border-slate-600 bg-white px-6 py-2 duration-200 hover:bg-slate-50">
					<h1 className="text-3xl">Tim.Allen</h1>
					<div className="w-1/2 overflow-hidden rounded-full shadow-md">
						<img
							alt="Tim.Allen"
							src="https://firebasestorage.googleapis.com/v0/b/social-share-90d8f.appspot.com/o/users%2FtnfcShXsn3RhnyyBsoMgO7dk2Vy2%2FprofilePicture.png?alt=media&token=0cac746f-1ea6-404a-94bb-9a403bc507ce"
						></img>
					</div>
					<SocialButton href="https://sharrin.gs/tim.allen">Profil ansehen</SocialButton>
				</div>
				<div className="flex cursor-pointer flex-col items-center justify-center gap-4 rounded-2xl border-2 border-slate-600 bg-white px-6 py-2 hover:bg-slate-50">
					<h1 className="text-3xl">Mr_Reeze</h1>
					<div className="w-1/2 overflow-hidden rounded-full shadow-md">
						<img
							alt="Mr_Reeze"
							src="https://firebasestorage.googleapis.com/v0/b/social-share-90d8f.appspot.com/o/users%2FIQ7L3TU3d8huS7mG0zhCBm94zdq2%2FprofilePicture.png?alt=media&token=83d33490-1866-47fd-86b0-399d408a07d0"
						></img>
					</div>
					<SocialButton href="https://sharrin.gs/mr_reeze_beispiel">Profil ansehen</SocialButton>
				</div>
			</div>
		</Section>
	);
};

const Section = ({ className, children }) => {
	return <section className={`${className} flex w-full justify-center p-4`}>{children}</section>;
};

export default Home;

import React, { useState, useRef, useEffect } from "react";
import useFirebase from "../hooks/useFirebase";
import { useNavigate } from "react-router-dom";
import AvatarEditor from "react-avatar-editor";
import { BlockPicker, TwitterPicker, CirclePicker, SketchPicker } from "react-color";
import { useDropzone } from "react-dropzone";
import { useSnackbar } from "notistack";
import Helmet from "react-helmet";

import Navbar from "../components/Navbar";
import Lines from "../components/Lines";
import Input from "../components/Input";
import Button from "../components/Button";
import LoadingScreen from "../components/LoadingScreen";

import { calculateColorPalette, getColorFromString } from "../assets/ColorCalculation";

import { useTranslation } from "react-i18next";

function Initialize() {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const {
		functions,
		user,
		httpsCallable,
		firestore,
		doc,
		getDoc,
		getStorage,
		ref,
		uploadBytes,
		getDownloadURL,
		profilePictureUrl,
		setProfilePictureUrl,
		analytics,
		logEvent,
	} = useFirebase();
	const [username, setUsername] = useState("");
	const [bio, setBio] = useState("");
	const [initialBio, setInitialBio] = useState("");
	const [accentColor, setAccentColor] = useState("");
	const [initialAccentColor, setInitialAccentColor] = useState("");
	const [available, setAvailable] = useState(true);
	const [loading, setLoading] = useState(false);
	const nav = useNavigate();
	const storage = getStorage();

	useEffect(() => {
		setLoading(true);
		if (user) {
			setPbUrl(profilePictureUrl);

			setLoading(false);
		} else {
			setLoading(false);
		}
		// eslint-disable-next-line
	}, [profilePictureUrl]);

	useEffect(() => {
		checkuser();
		getUserdata();
		// eslint-disable-next-line
	}, [user]);

	async function getUserdata() {
		const docRef = doc(firestore, "users", user?.uid);
		const docSnap = await getDoc(docRef);
		setBio(docSnap.data().bio || "");
		setInitialBio(docSnap.data().bio || "");
		setAccentColor(docSnap.data().accentColor || "");
		setInitialAccentColor(docSnap.data().accentColor || "");
	}

	async function checkuser() {
		if (user !== null && user !== "") {
			const docRef = doc(firestore, "users", user?.uid);
			const docSnap = await getDoc(docRef);

			if (docSnap.data().username === "" || !docSnap.data().username) {
				// Zwingen
				nav("/initialize");
			}
			setUsername(user.displayName);
		} else if (user !== "") {
			nav("/");
		}
	}

	useEffect(() => {
		calculateColorPalette(initialAccentColor || getColorFromString(username));
	}, [initialAccentColor]);

	const changeUsername = httpsCallable(functions, "changeUsername");
	const changeBio = httpsCallable(functions, "changeBio");
	const changeAccentColor = httpsCallable(functions, "changeAccentColor");

	const checkUsernameAvailability = async (checkusername) => {
		if (checkusername.length === 1) {
			setUsername(checkusername.replace(/[^0-9a-z_]/gi, "").substring(0, 30));
			const docRef = doc(firestore, "usernames", checkusername.toLowerCase());
			const docSnap = await getDoc(docRef);
			setAvailable(docSnap.data()?.user_id === user.uid || !docSnap.data());
		} else {
			setUsername(checkusername.replace(/[^0-9a-z_.@-]/gi, "").substring(0, 30));
			const docRef = doc(firestore, "usernames", checkusername.toLowerCase());
			const docSnap = await getDoc(docRef);
			setAvailable(docSnap.data()?.user_id === user.uid || !docSnap.data());
		}
	};

	const changeName = () => {
		setLoading(true);
		changeUsername({ username: username })
			.then((result) => {
				setLoading(false);
				logEvent(analytics, "changeUsername", {
					username: username,
					old_username: user.displayName,
				});
				// const data = result.data;
				// const sanitizedMessage = data.text;
				nav("/dashboard");
				// eslint-disable-next-line no-restricted-globals
				location.reload();
			})
			.catch((error) => {
				setLoading(false);
				console.log(error);
			});
	};

	const changeBiography = () => {
		setLoading(true);
		changeBio({ bio: bio.substring(0, 150) })
			.then((result) => {
				setLoading(false);
				logEvent(analytics, "changeBio", {
					bio: bio,
				});
				// const data = result.data;
				// const sanitizedMessage = data.text;
				nav("/dashboard");
				// eslint-disable-next-line no-restricted-globals
				location.reload();
			})
			.catch((error) => {
				setLoading(false);
				console.log(error);
			});
	};

	const handleChangeAccentColor = () => {
		setLoading(true);

		changeAccentColor({ accentColor: accentColor })
			.then((result) => {
				console.log(result.data.message);
				setLoading(false);
				logEvent(analytics, "changeAccentColor", {
					accentColor: accentColor,
				});

				setInitialAccentColor(accentColor);

				enqueueSnackbar("Changed Accent Color", { variant: "success" });

				// nav("/dashboard");
				// // eslint-disable-next-line no-restricted-globals
				// location.reload();
			})
			.catch((error) => {
				setLoading(false);
				console.log(error);
			});
	};

	const changeProfilePicture = () => {
		setLoading(true);
		if (editorRef.current) {
			const storageRef = ref(storage, `/users/${user.uid}/profilePicture.png`);

			const image = dataURItoBlob(editorRef.current.getImageScaledToCanvas().toDataURL("image/png"));

			const metadata = {
				contentType: "image/png",
			};

			uploadBytes(storageRef, image, metadata).then((result) => {
				if (result.metadata) {
					setImageChanges(1);
					getDownloadURL(ref(storage, `users/${user.uid}/profilePicture.png`))
						.then((url) => {
							logEvent(analytics, "changeProfilePicture", {
								profilePictureUrl: url,
							});
							setProfilePictureUrl(url);
							enqueueSnackbar(t("settings.snackbar.profile_picture_changed"), { variant: "success" });
						})
						.catch((error) => {
							console.log(error);
						});
					setLoading(false);
				}
			});
		}
	};

	const changeSettings = () => {
		// ? Checks if the user has changed the image, to prevent uploading the same image and CORS errors
		if (imageChanges >= 2) changeProfilePicture();
		if (user.displayName !== username) changeName();
		if (initialBio !== bio) changeBiography();
		if (initialAccentColor !== accentColor) handleChangeAccentColor();
	};

	function dataURItoBlob(dataURI) {
		let byteString = atob(dataURI.split(",")[1]);
		let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
		let ab = new ArrayBuffer(byteString.length);
		let ia = new Uint8Array(ab);
		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}
		return new Blob([ab], { type: mimeString });
	}

	const [pbZoom, setPbZoom] = useState(1);
	const [pbRotationIndex, setPbRotationIndex] = useState(1);
	const [pbRotation, setPbRotation] = useState(0);
	const [pbUrl, setPbUrl] = useState("logo512.png");
	const [showDrop, setShowDrop] = useState(false);
	const [imageChanges, setImageChanges] = useState(0);
	const editorRef = useRef();

	const rotate90deg = () => {
		if (pbRotationIndex > 3) {
			setPbRotationIndex(1);
		} else {
			setPbRotationIndex(pbRotationIndex + 1);
		}
		setPbRotation(90 * pbRotationIndex);
	};

	const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
		noClick: true,
		noKeyboard: true,
		accept: "image/*",
	});

	const clamp = (value, min, max) => {
		return Math.max(min, Math.min(max, value));
	};

	useEffect(() => {
		if (acceptedFiles.length > 0) {
			setPbUrl(acceptedFiles[0]);
		}
	}, [acceptedFiles]);

	return (
		<div className="md:mt-36">
			<Helmet>
				<title>{t("settings.title")}</title>
			</Helmet>
			<Navbar centerElements={<p className="text-2xl font-bold">{t("settings.pagetitle")}</p>} />
			<div className="flex h-full flex-col items-center gap-10 pt-28 md:justify-center md:pt-0">
				<Lines />

				<SettingsCard title={t("settings.avatareditor.title")}>
					<div className="flex w-full flex-col items-center justify-center gap-8 sm:flex-row">
						<div
							{...getRootProps({
								onDragEnter: () => setShowDrop(true),
								onDragLeave: () => setShowDrop(false),
								onDrop: () => setShowDrop(false),
							})}
						>
							<div className="relative shadow-xl">
								<AvatarEditor
									ref={editorRef}
									image={pbUrl}
									width={200}
									height={200}
									border={0}
									color={[239, 116, 116, 0.6]} // RGBA
									scale={parseFloat(pbZoom)}
									rotate={parseFloat(pbRotation)}
									borderRadius={250}
									onImageChange={() => setImageChanges((changes) => changes + 1)}
									onWheel={(e) => {
										const wheel = (e.deltaY / 100) * -1;
										setPbZoom(clamp(parseFloat(pbZoom) + wheel / 10, 1, 4));
									}}
								/>
								<div
									className={`${
										showDrop ? "opacity-100" : "opacity-0"
									} pointer-events-none absolute inset-0 bg-gray-200/40 duration-300`}
								>
									<div className="pointer-events-none absolute left-1/2 top-1/2 h-[90%] w-[90%] -translate-x-1/2 -translate-y-1/2 rounded-[50px] border-4 border-dashed border-white"></div>
									<div className="pointer-events-none z-[150] flex h-full w-full flex-col items-center justify-center">
										<div className="invert">
											<ion-icon name="add" />
										</div>
										<p className="font-medium text-white">{t("settings.avatareditor.droptext")}</p>
									</div>
								</div>
							</div>
							<input {...getInputProps()} />
						</div>
						<div className="w-full sm:w-auto">
							<Input
								onChange={(e) => {
									setPbZoom(e.target.value);
								}}
								type="range"
								min="1"
								max="4"
								step="0.01"
								label={t("settings.avatareditor.zoom")}
								value={pbZoom}
							/>
							<Input
								onChange={(e) => {
									setPbRotation(e.target.value);
								}}
								type="range"
								min="1"
								max="360"
								step="1"
								label={t("settings.avatareditor.rotation")}
								value={pbRotation}
							/>
							<Button onClick={() => rotate90deg()}>{t("settings.avatareditor.rotate")} 90</Button>
							<Input
								onChange={(e) => {
									if (!e.target.files || e.target.files.length === 0) {
										setPbUrl("logo512.png");
										return;
									}
									setPbUrl(e.target.files[0]);
								}}
								type="file"
								label="Browse"
								accept="image/*"
							/>
						</div>
					</div>
				</SettingsCard>

				<SettingsCard title={t("settings.bio.title")}>
					<div className="items-left flex w-full flex-col justify-center gap-4 sm:w-auto">
						<Input
							className="w-full text-black"
							type="text"
							value={bio}
							onChange={(e) => setBio(e.target.value.substring(0, 150))}
							label={t("settings.bio.placeholder")}
						></Input>
						<p className="text-black">{150 - bio.length}</p>
					</div>
				</SettingsCard>

				<SettingsCard title={t("settings.accentcolor.title")}>
					<div className="flex w-full flex-col items-center justify-center gap-4 sm:w-auto">
						<BlockPicker
							triangle="hide"
							color={accentColor || getColorFromString(username)}
							className="drop-shadow-md"
							colors={[
								"#D9E3F0",
								"#F47373",
								"#697689",
								"#37D67A",
								"#2CCCE4",
								"#555555",
								"#dce775",
								"#ff8a65",
								"#ba68c8",
								initialAccentColor || getColorFromString(username),
							]}
							onChange={(color) => setAccentColor(color.hex)}
						/>
						<Button onClick={() => setAccentColor("")}>{t("settings.accentcolor.usernamecolor")}</Button>
					</div>
				</SettingsCard>

				<SettingsCard title={t("settings.username.title")}>
					<div className="flex w-full flex-col items-center justify-center gap-4 sm:w-auto">
						<Input
							className="w-full text-black"
							type="text"
							value={username}
							onChange={(e) => checkUsernameAvailability(e.target.value)}
							pattern="[a-zA-Z0-9!@$_.]{4,25}"
							label={t("settings.username.input")}
						></Input>
						<p className="text-black">
							https://sharrin.gs/
							<span className={`${available ? "text-green-500" : "text-red-500"}`}>{username}</span>{" "}
							{available ? t("settings.username.available") : t("settings.username.given")}
						</p>
					</div>
				</SettingsCard>

				<div className="m-10"></div>
				<div className="fixed bottom-8">
					<Button
						className={`${
							loading ? "bg-gray-800 text-gray-400" : "bg-dark-300"
						} w-full scale-125 cursor-pointer p-4  ring-2 ring-accent-200 drop-shadow-xl`}
						disabled={loading}
						onClick={changeSettings}
					>
						{t("settings.save")}
					</Button>
				</div>
			</div>
			<LoadingScreen loading={loading} />
		</div>
	);
}

function SettingsCard({ title, children }) {
	return (
		<div className="w-11/12 rounded-3xl bg-white p-8 ring-1 ring-neutral-200 drop-shadow-lg md:w-[48rem]">
			<div className="absolute -top-5 left-6 flex flex-row items-center gap-4 text-2xl font-bold drop-shadow-md">
				<span>{title}</span>
			</div>
			{children}
		</div>
	);
}

export default Initialize;

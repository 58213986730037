import React from "react";
import { PlusIcon } from "@heroicons/react/outline";

export default function SocialLinkButton({
	onClick,
	title,
	icon,
	showIcon,
	showTitle,
	className,
	loading,
	active,
	addButton,
}) {
	return (
		<div
			className={`group ${className} flex h-24 w-24 flex-col items-center justify-center backdrop-blur-sm ${
				!active ? "opacity-50" : "opacity-100"
			} cursor-pointer rounded-3xl border-[1px] border-white bg-white shadow-smooth hover:border-2 hover:border-accent-200`}
			onClick={onClick}
		>
			{loading ? (
				<div className="h-1/3 w-1/3 animate-spin rounded-full border-t-4 border-gray-200"></div>
			) : (
				<>
					<p className={`duration-150 group-hover:scale-105 ${showTitle ? "visible" : "hidden"}`}>{title}</p>
					<div
						className={`${
							showIcon ? "visible" : "hidden"
						} flex items-center justify-center duration-150 group-hover:scale-105`}
					>
						{/* <ion-icon name={icon} /> */}
						{addButton ? (
							<PlusIcon className="w-14" />
						) : (
							<img src={`/logos/${icon}.svg`} className="w-14  group-hover:drop-shadow-xl" alt={icon} />
						)}
					</div>
				</>
			)}
		</div>
	);
}

import React from "react";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function Imprint() {
	return (
		<div className="flex h-screen flex-col justify-between">
			<div>
				<Navbar centerElements={<p className="text-2xl font-bold">Imprint</p>} />
				<div className="flex h-full w-full items-center justify-center py-40">
					<p>
						ANGABEN GEMÄSS § 5 TM
						<br />
						Christopher Brandt
						<br />
						Am Dorfplatz, 6<br />
						76835 Hainfeld
						<br />
						KONTAKT
						<br />
						Telefon: 01703537300
						<br />
						E-Mail: kostariprints@gmail.com
						<br />
						Quelle: eRecht24
					</p>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default Imprint;

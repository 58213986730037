import { useState } from "react";
import Button from "./Button";
import { useTranslation, Trans } from "react-i18next";

export default function CookiePopup() {
	const { t } = useTranslation();
	const [accepted, setAccepted] = useState(false);
	const handleAccept = () => {
		localStorage.setItem("cookies-accepted", true);
		if (!accepted) setAccepted(true);
	};

	return (
		<div>
			{!localStorage.getItem("cookies-accepted") && (
				<div
					className="fixed bottom-16 left-1/2 z-20 w-5/6 -translate-x-1/2 rounded-2xl border-2 border-black bg-white p-4 drop-shadow-lg md:w-auto md:p-8"
					id="cookiepopup"
				>
					<Trans i18nKey="cookiepopup.text">
						<a href="/privacy-policy" className="text-blue-600 hover:text-blue-800">
							Privacy Policy
						</a>
						<a href="/imprint" className="text-blue-600 hover:text-blue-800">
							Imprint
						</a>
					</Trans>
					<Button className="mt-4" onClick={handleAccept}>
						{t("cookiepopup.accept")}
					</Button>
				</div>
			)}
		</div>
	);
}

import { useEffect, useState, Fragment } from "react";
import { Dialog, Transition, Popover } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import useFirebase from "../hooks/useFirebase";
import { useSnackbar } from "notistack";
import Helmet from "react-helmet";
import copy from "copy-to-clipboard";

import { PencilIcon, QrcodeIcon, ClipboardCopyIcon } from "@heroicons/react/outline";
import { CheckCircleIcon } from "@heroicons/react/solid";

import Lines from "../components/Lines";
import Button from "../components/Button";
import Navbar from "../components/Navbar";
import Switch from "../components/Switch";
import SocialLinkButton from "../components/SocialLinkButton";
import { calculateColorPalette, getColorFromString, getColorPalette } from "../assets/ColorCalculation";
import LoadingScreen from "../components/LoadingScreen";

import { QRCode } from "react-qrcode-logo";

import { useTranslation } from "react-i18next";

import { useTour } from "@reactour/tour";

export default function NewDashboard() {
	const { setIsOpen, isOpen, ...rest } = useTour();
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const { functions, httpsCallable, user, doc, getDoc, firestore, profilePictureUrl, setItems, items, userProfile } =
		useFirebase();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [currentIndex, setCurrentIndex] = useState(false);
	const [color, setColor] = useState("#ee6262");
	const [iconSearch, setIconSearch] = useState("");
	const nav = useNavigate();

	const updateItemFunction = httpsCallable(functions, "updateItem");
	const deleteItemFunction = httpsCallable(functions, "deleteItem");

	useEffect(() => {
		checkuser();
		// eslint-disable-next-line
	}, [user]);

	useEffect(() => {
		calculateColorPalette(color);
	}, [color]);

	useEffect(() => {
		if (isModalOpen && !isOpen) {
			localStorage.setItem("has-seen-tutorial", true);
		}
	}, [isOpen]);

	async function checkuser() {
		if (user !== null && user !== "") {
			const docRef = doc(firestore, "users", user.uid);
			const docSnap = await getDoc(docRef);

			if (!docSnap || !docSnap.data() || docSnap.data().username === "" || !docSnap.data().username) {
				// Zwingen
				nav("/initialize");
			} else {
				setColor(docSnap?.data()?.accentColor ? docSnap?.data()?.accentColor : getColorFromString(user.displayName));
			}
		} else if (user !== "") {
			nav("/");
		}
	}

	function closeModal() {
		if (items[currentIndex].id) {
			saveItem();
		} else {
			createItem();
		}
		setIconSearch("");
		setIsModalOpen(false);
	}

	function openModal(index) {
		setCurrentIndex(index);
		setIsModalOpen(true);
	}

	const addItem = () => {
		if (userProfile?.data().membership === 1 && items.length >= 5) {
			enqueueSnackbar(t("dashboard.itemeditor.limitreached", { limit: 5 }), {
				variant: "warning",
			});
		} else {
			let arr = [...items];
			arr.push({
				name: "",
				icon: "apple-ar",
				show_name: false,
				show_icon: true,
				active: true,
				entrys: [
					{
						name: "",
						link: "",
					},
				],
			});
			setItems(arr);
			setCurrentIndex(items.length);
			setIsModalOpen(true);
		}
	};

	const saveItem = () => {
		updateItemFunction({
			...items[currentIndex],
			item_id: items[currentIndex].id,
		})
			.then((result) => {
				// const data = result.data;
				// const sanitizedMessage = data.text;
				// fetchData();
				enqueueSnackbar(t("dashboard.snackbar.saveditem", { name: items[currentIndex].name }), { variant: "success" });
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const createItem = () => {
		if (
			items[currentIndex] &&
			items[currentIndex].icon &&
			items[currentIndex].entrys &&
			items[currentIndex].entrys.length > 0 &&
			items[currentIndex].entrys[0].link !== ""
		) {
			setLoading(true);
			updateItemFunction({
				...items[currentIndex],
			})
				.then((result) => {
					// const data = result.data;
					// const sanitizedMessage = data.text;
					setLoading(false);
					// fetchData();
				})
				.catch((error) => {
					console.log(error);
					setLoading(false);
				});
		}
	};

	const deleteItem = (id) => {
		setLoading(true);
		deleteItemFunction({
			item_id: id,
		})
			.then((result) => {
				// const data = result.data;
				// const sanitizedMessage = data.text;
				// fetchData();
				enqueueSnackbar(t("dashboard.snackbar.deleteditem", { name: items[currentIndex].name }), {
					variant: "success",
				});
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			});
	};

	return (
		<div className="mt-24 flex flex-col items-center justify-center gap-12 px-2 md:mt-0 md:mb-0 md:h-screen md:px-4">
			<Helmet>
				<title>{t("dashboard.title")}</title>
			</Helmet>
			<Lines />
			<Navbar centerElements={<p className="text-2xl font-bold">{t("dashboard.pagetitle")}</p>} />
			<div className="flex flex-col items-center justify-center gap-8 text-black">
				<ProfileAvatar profilePictureUrl={profilePictureUrl} />
				<UsernameBar />
				<Items items={items} openModal={openModal} addItem={addItem} />
			</div>
			<EditItemModal
				isModalOpen={isModalOpen}
				closeModal={closeModal}
				items={items}
				setItems={setItems}
				currentIndex={currentIndex}
				deleteItem={deleteItem}
				setIsModalOpen={setIsModalOpen}
			/>
			<LoadingScreen loading={loading} />
		</div>
	);
}

function Items({ items, openModal, addItem }) {
	return (
		<div className="flex flex-row flex-wrap justify-center gap-4 text-black">
			{items.map((item, index) => (
				<motion.div
					initial={{ opacity: 0, rotate: -15 }}
					animate={{ opacity: 1, rotate: 0 }}
					transition={{ delay: 0.05 * index }}
					key={index}
				>
					<SocialLinkButton
						onClick={() => openModal(index)}
						title={item.name}
						showTitle={item.show_name}
						icon={item.icon}
						showIcon={item.show_icon}
						active={item.active}
					/>
				</motion.div>
			))}
			<AddItemButton addItem={addItem} />
		</div>
	);
}

function EditItemModal({ isModalOpen, closeModal, items, setItems, currentIndex, deleteItem, setIsModalOpen }) {
	const { t } = useTranslation();
	const { setIsOpen, isOpen, ...rest } = useTour();
	const [iconSearch, setIconSearch] = useState("");

	const showTutorial = () => {
		if (!localStorage.getItem("has-seen-tutorial")) {
			setIsOpen(true);
		}
	};

	return (
		<Transition show={isModalOpen} as={Fragment}>
			<Dialog as="div" className="fixed inset-0 z-10" onClose={() => !isOpen && closeModal(currentIndex)}>
				<Dialog.Overlay className="fixed inset-0 bg-black opacity-20" />
				<div className="min-h-screen px-4 text-center">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0" />
					</Transition.Child>

					<span className="inline-block h-screen align-middle" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
						afterEnter={() => showTutorial()}
					>
						<div className="my-8 inline-block w-full max-w-md transform rounded-2xl bg-white p-5 text-left align-middle shadow-xl transition-all">
							<Dialog.Title as="h3" className="text-lg font-bold leading-6 text-black">
								<div className="flex flex-row items-center justify-between gap-2">
									<Switch
										id="tour-toggle-item"
										checked={items[currentIndex]?.active}
										onChange={() => {
											let arr = [...items];
											arr[currentIndex] = {
												...arr[currentIndex],
												active: !arr[currentIndex]?.active,
											};
											setItems(arr);
										}}
									/>
									<div className="flex flex-row items-center">
										<div className="flex items-center gap-1">
											<Popover className="relative">
												<Popover.Button>
													<div id="tour-choose-item-image" className="flex flex-row items-center gap-1">
														<div className="flex h-9 w-9 cursor-pointer items-center justify-center rounded-lg border-[1px] border-black bg-gray-50">
															{/* <ion-icon name={items[currentIndex]?.icon} size="large" /> */}
															<img src={`/logos/${items[currentIndex]?.icon}.svg`} className="w-6" alt="" />
														</div>
													</div>
												</Popover.Button>
												<Transition
													as={Fragment}
													enter="transition ease-out duration-200"
													enterFrom="opacity-0 translate-y-1"
													enterTo="opacity-100 translate-y-0"
													leave="transition ease-in duration-150"
													leaveFrom="opacity-100 translate-y-0"
													leaveTo="opacity-0 translate-y-1"
												>
													<Popover.Panel className="absolute z-20 mt-0 w-80 max-w-sm -translate-x-14 transform px-0 md:left-40 md:-translate-x-1/2 lg:max-w-3xl">
														<div className="overflow-hidden rounded-2xl bg-gray-50 ring-1 ring-black ring-opacity-25 drop-shadow-xl">
															<div className="absolute flex w-full justify-center">
																<input
																	placeholder={t("dashboard.itemeditor.iconname")}
																	value={iconSearch}
																	onChange={(e) => {
																		setIconSearch(e.target.value);
																	}}
																	maxLength={50}
																	className="border-200 mt-3 w-11/12 rounded-lg border-2 p-1 text-2xl font-bold text-black drop-shadow-xl"
																></input>
															</div>
															<div className="grid max-h-72 grid-cols-4 gap-2 overflow-y-auto p-2 pt-16">
																{icons
																	.filter((item) => item.includes(iconSearch.toLowerCase()))
																	.map((icon, index) => (
																		<div
																			key={index}
																			className="flex cursor-pointer items-center justify-center rounded-lg border-[1px] border-black p-1"
																			onClick={() => {
																				let arr = [...items];
																				arr[currentIndex].icon = icon;
																				setItems(arr);
																			}}
																		>
																			{/* svg file */}
																			<img src={`/logos/${icon}.svg`} className="h-10 w-10" alt={icon} />
																		</div>
																	))}
															</div>
														</div>
													</Popover.Panel>
												</Transition>
											</Popover>
											<div id="tour-toggle-item-image" className="flex cursor-pointer items-center">
												<ion-icon
													name={`${items[currentIndex]?.show_icon ? "eye-outline" : "eye-off-outline"}`}
													size="small"
													onClick={() => {
														let arr = [...items];
														arr[currentIndex].show_icon = !items[currentIndex].show_icon;
														setItems(arr);
													}}
												/>
											</div>
										</div>
										<div className="ml-2 flex items-center gap-1">
											<input
												id="tour-choose-item-title"
												placeholder={t("dashboard.itemeditor.itemname")}
												value={items[currentIndex]?.name}
												onChange={(e) => {
													let arr = [...items];
													arr[currentIndex].name = e.target.value;
													setItems(arr);
												}}
												className="w-4/5 text-2xl font-bold leading-6 text-black"
											></input>
											<div id="tour-toggle-item-title" className="flex cursor-pointer items-center">
												<ion-icon
													name={`${items[currentIndex]?.show_name ? "eye-outline" : "eye-off-outline"}`}
													size="small"
													onClick={() => {
														let arr = [...items];
														arr[currentIndex].show_name = !items[currentIndex].show_name;
														setItems(arr);
													}}
												/>
											</div>
										</div>
									</div>
									<div>
										<div
											id="tour-delete-item"
											className="float-right inline-flex cursor-pointer justify-center rounded-md border border-transparent bg-red-600 px-2 py-1 text-sm font-medium text-white hover:bg-red-700"
											onClick={() => {
												if (items[currentIndex]?.id) {
													deleteItem(items[currentIndex].id);
												} else {
													let arr = [...items];
													arr.splice(currentIndex, 1);
													setItems(arr);
												}
												setIsModalOpen(false);
											}}
										>
											<ion-icon name="trash-outline" size="small" />
										</div>
									</div>
								</div>
							</Dialog.Title>
							<div className="mt-2 mb-4 w-full border-b-2" />
							<div className="flex flex-col items-center justify-center gap-4 text-black">
								{items[currentIndex]?.entrys.map((entry, index) => (
									<div className="flex flex-row" key={index}>
										<div className="border-[1px] border-gray-600">
											<input
												id="tour-item-content-title"
												placeholder={t("dashboard.itemeditor.entry.name")}
												className="w-full"
												value={entry.name}
												onChange={(e) => {
													let arr = [...items];
													arr[currentIndex].entrys[index].name = e.target.value;
													setItems(arr);
												}}
											></input>
											<input
												id="tour-item-content-link"
												placeholder={t("dashboard.itemeditor.entry.link")}
												className="w-full"
												value={entry.link}
												onChange={(e) => {
													let arr = [...items];
													arr[currentIndex].entrys[index].link = e.target.value;
													setItems(arr);
												}}
											></input>
										</div>
										<div
											id="tour-item-delete-content"
											className="flex w-8 cursor-pointer items-center justify-center bg-red-600"
											onClick={() => {
												let arr = [...items];
												// slice the entry out of the array
												arr[currentIndex].entrys.splice(index, 1);
												setItems(arr);
											}}
										>
											<ion-icon name="trash-outline" size="small"></ion-icon>
										</div>
									</div>
								))}
								<div
									id="tour-item-add-content"
									className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-green-500"
									onClick={() => {
										let arr = [...items];
										arr[currentIndex].entrys.push({
											name: "",
											link: "",
										});
										setItems(arr);
									}}
								>
									<ion-icon name="add-outline" size="small"></ion-icon>
								</div>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	);
}

function AddItemButton({ addItem }) {
	return (
		<motion.div initial={{ opacity: 0, rotate: -15 }} animate={{ opacity: 1, rotate: 0 }}>
			<SocialLinkButton icon="apple" addButton showIcon="true" active={true} onClick={addItem} />
		</motion.div>
	);
}

function UsernameBar() {
	const { t } = useTranslation();
	const [isQrOpen, setIsQrOpen] = useState(false);
	const [effect, setEffect] = useState();
	const { user, userProfile } = useFirebase();

	const closeQrModal = () => {
		setIsQrOpen(false);
	};

	return (
		<div className="flex flex-row flex-wrap items-center justify-center gap-2 md:gap-4">
			<Button onClick={() => setIsQrOpen(true)}>
				<QrcodeIcon className="h-5 w-5 text-contrast md:h-8 md:w-8" />
				<QrModal isQrOpen={isQrOpen} closeQrModal={closeQrModal} displayName={user?.displayName} />
			</Button>
			<div>
				<div className="flex flex-row items-center justify-center gap-1 md:gap-2">
					<p
						className={`relative text-[9vw] font-bold drop-shadow-lg md:text-6xl ${
							userProfile?.data()?.membership >= 2
								? "text-amber-500 drop-shadow-[0_4px_3px_rgba(245,158,11,0.3)]"
								: "text-black drop-shadow-lg"
						} `}
					>
						{user && user.displayName}
					</p>

					<p className={`drop-shadow-lg`}>
						{userProfile?.data()?.badges?.verified && <CheckCircleIcon className="h-8 text-blue-800 md:h-12" />}
					</p>
				</div>
				<div
					className={`flex flex-row items-center justify-start gap-1 ${effect && "animate-flash"}`}
					onAnimationEnd={() => setEffect(false)}
				>
					<ClipboardCopyIcon
						className={`h-4 cursor-pointer`}
						onClick={() => {
							setEffect(true);
							copy(`https://sharrin.gs/${user?.displayName?.toLowerCase()}`);
						}}
					/>
					<a
						href={`/${user?.displayName?.toLowerCase()}`}
						target="_blank"
						rel="noreferrer"
						className="cursor-pointer pl-[3px] hover:underline"
					>
						https://sharrin.gs/
						{user?.displayName?.toLowerCase()}
					</a>
				</div>
			</div>
		</div>
	);
}

function ProfileAvatar({ profilePictureUrl }) {
	const nav = useNavigate();

	return (
		<div className="relative">
			<div className="pointer-events-all relative h-40 w-40 overflow-hidden rounded-full border-4 border-accent-200 object-cover shadow-smooth backdrop-blur-sm">
				<img src={profilePictureUrl} alt="Profile" />
				<div className="absolute flex h-full w-full items-center justify-center bg-gray-100">
					<div className="h-1/3 w-1/3 animate-spin rounded-full border-t-4 border-gray-200"></div>
				</div>
				<div
					className="absolute inset-0 flex cursor-pointer items-center justify-center bg-black bg-opacity-20 opacity-0 hover:opacity-100"
					onClick={() => nav("/settings")}
				>
					<PencilIcon className="h-14 w-14 text-white" />
				</div>
			</div>
		</div>
	);
}

function QrModal({ isQrOpen, closeQrModal, displayName }) {
	const { t } = useTranslation();

	return (
		<Transition appear show={isQrOpen} as={Fragment}>
			<Dialog as="div" className="fixed inset-0 z-10" onClose={closeQrModal}>
				<Dialog.Overlay className="fixed inset-0 bg-black/40" />
				<div className="flex min-h-screen items-center justify-center px-4 text-center">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0" />
					</Transition.Child>
					<span className="inline-block h-screen align-middle" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<div className="my-8align-middle scale-75 transform rounded-2xl bg-white p-5 shadow-xl transition-all placeholder:inline-block md:scale-100">
							<span className="text-3xl font-semibold">{t("dashboard.qr.title")}</span>
							<div className="jusitfy-center flex h-full w-full items-center">
								<QRCode
									value={`${window.location.origin}/${displayName}`}
									logoImage="logo512.png"
									logoWidth={200}
									logoOpacity={0.3}
									size={300}
									qrStyle="squares"
									eyeRadius={8}
									fgColor={getColorPalette()["300"]}
								/>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	);
}

const icons = [
	"2k",
	"alipay",
	"amazon",
	"amplify",
	"android",
	"angular",
	"apple-appstore",
	"apple-ar",
	"apple",
	"battle-net",
	"behance",
	"bitbucket",
	"bitcoin",
	"brawl-stars",
	"buffer",
	"capacitor",
	"chrome",
	"closed-captioning",
	"codepen",
	"css3",
	"designernews",
	"deviantart",
	"discord",
	"docker",
	"dribbble",
	"dropbox",
	"ea",
	"edge",
	"electron",
	"epic-games",
	"euro",
	"facebook",
	"figma",
	"firebase",
	"firefox",
	"flickr",
	"fortnite",
	"foursquare",
	"github",
	"gitlab",
	"google-playstore",
	"google",
	"hackernews",
	"html5",
	"instagram",
	"ionic",
	"ionitron",
	"javascript",
	"laravel",
	"league-of-legends",
	"linkedin",
	"mail",
	"markdown",
	"mastodon",
	"medium",
	"microsoft",
	"minecraft",
	"nintendo-switch",
	"no-smoking",
	"nodejs",
	"npm",
	"octocat",
	"paypal",
	"pinterest",
	"playstation",
	"pwa",
	"python",
	"react",
	"reddit",
	"rocket-league",
	"rss",
	"sass",
	"sharrings",
	"shopify",
	"skype",
	"slack",
	"snapchat",
	"soundcloud",
	"spotify",
	"stackoverflow",
	"steam",
	"stencil",
	"tableau",
	"teamspeak",
	"tiktok",
	"tumblr",
	"tux",
	"twitch",
	"twitter",
	"ubisoft",
	"usd",
	"valorant",
	"venmo",
	"vercel",
	"vimeo",
	"vk",
	"vue",
	"web-component",
	"wechat",
	"whatsapp",
	"windows",
	"wordpress",
	"xbox",
	"xing",
	"yahoo",
	"yen",
	"youtube",
];

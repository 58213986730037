import React from "react";

function LoadingScreen({ loading }) {
	return (
		<div
			className={`fixed top-0 left-0 z-[1000] flex h-screen w-screen items-center justify-center bg-black/50 ${
				!loading && "hidden"
			}`}
		>
			<div className="m-4 h-6 w-6 animate-[bounce_1s_ease-in-out_infinite] rounded-full bg-white"></div>
			<div className="m-4 h-6 w-6 animate-[bounce_1.1s_ease-in-out_infinite] rounded-full bg-white"></div>
			<div className="m-4 h-6 w-6 animate-[bounce_1.2s_ease-in-out_infinite] rounded-full bg-white"></div>
		</div>
	);
}

export default LoadingScreen;

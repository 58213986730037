import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

import "./i18n";

ReactDOM.render(
	<React.StrictMode>
		<Suspense fallback={<div className="w-screen h-screen flex justify-center items-center">Loading...</div>}>
			<App />
		</Suspense>
	</React.StrictMode>,
	document.getElementById("root")
);

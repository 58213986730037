import { useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useParams, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import useFirebase from "../hooks/useFirebase";
import copy from "copy-to-clipboard";
// import sharded from "../assets/sharded-counter";

import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import Helmet from "react-helmet";

import { calculateColorPalette, getColorFromString } from "../assets/ColorCalculation";

import Lines from "../components/Lines";
import SocialLinkButton from "../components/SocialLinkButton";
import LoadingScreen from "../components/LoadingScreen";

import { CheckCircleIcon } from "@heroicons/react/solid";

export default function Profile() {
	const { collection, query, where, firestore, getDocs, getStorage, ref, getDownloadURL } = useFirebase();
	const { usernameParam } = useParams();
	const location = useLocation();
	const [items, setItems] = useState([]);
	const [loading, setLoading] = useState(false);
	const storage = getStorage();
	const [profilePictureUrl, setProfilePictureUrl] = useState("logo512.png");
	const [color, setColor] = useState();
	const [username, setUsername] = useState(usernameParam);
	const [profileUser, setProfileUser] = useState();

	useEffect(() => {
		setColor(profileUser?.data()?.accentColor ? profileUser?.data()?.accentColor : getColorFromString(username));
		// var visits = new sharded.Counter("pages/hello-world", "views");
	}, [profileUser]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const usersRef = collection(firestore, "users");
				const userQuery = await query(usersRef, where("username", "==", username.toLowerCase()));
				const userSnap = await getDocs(userQuery);
				setProfileUser(userSnap.docs[0]);

				if (userSnap.empty) {
					console.log("No matching documents.");
					// TODO: Reffer to 404 or show error
				}

				setUsername(userSnap.docs[0].data().displayName);
				const itemQuery = query(
					collection(firestore, "users", userSnap.docs[0].id, "items"),
					where("active", "==", true)
				);
				const itemSnap = await getDocs(itemQuery);

				let arr = [];
				await itemSnap.forEach((doc) => {
					arr.push(doc.data());
				});
				setItems(arr);
				setLoading(false);
			} catch (error) {
				setLoading(false);
			}
		};

		fetchData();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const fetchProfilePicture = async () => {
			if (profileUser) {
				getDownloadURL(ref(storage, `users/${profileUser.id}/profilePicture.png`)).then((url) => {
					setProfilePictureUrl(url);
				});
			}
		};

		fetchProfilePicture();
		// eslint-disable-next-line
	}, [profileUser]);

	useEffect(() => {
		calculateColorPalette(color);
	}, [color]);

	return (
		<div className="h-screen">
			<Helmet>
				<meta property="og:title" content={`Sharrings - ${username}`} />
				<meta name="twitter:title" content={`Sharrings - ${username}`} />
				<meta name="title" content={`Sharrings - ${username}`} />
				<link rel="canonical" href={`https://sharrin.gs${location.pathname}`}></link>
				<title>{`Sharrings - ${username}`}</title>
			</Helmet>
			<div className="flex flex-col items-center justify-center pt-12 md:my-0 md:h-full md:pt-0">
				<Lines className="blur-sm" />
				<div className="mb-12">
					<div className="-left-1/2 flex flex-col items-center justify-center gap-8 md:left-0">
						<motion.div className="relative">
							<div className="pointer-events-all relative h-40 w-40 overflow-hidden rounded-full border-4 border-accent-200 object-cover shadow-smooth backdrop-blur-sm">
								<img src={profilePictureUrl} alt="Profile" />
								<div className="absolute flex h-full w-full items-center justify-center bg-gray-100">
									<div className="h-1/3 w-1/3 animate-spin rounded-full border-t-4 border-gray-200"></div>
								</div>
								<div className="absolute inset-0 bg-transparent"></div>
							</div>
						</motion.div>
						<div className="flex flex-col items-center justify-center gap-3">
							<div className="flex flex-row items-center justify-center gap-2 underline decoration-accent-200 decoration-2 underline-offset-4">
								<motion.p
									className={`relative text-[10vw] font-bold drop-shadow-lg md:text-6xl ${
										profileUser?.data()?.membership >= 2
											? "text-amber-500 drop-shadow-[0_4px_3px_rgba(245,158,11,0.3)]"
											: "text-black drop-shadow-lg"
									} `}
								>
									{username}
								</motion.p>
								<motion.p className={`drop-shadow-lg`}>
									{profileUser?.data()?.badges?.verified && <CheckCircleIcon className="h-8 text-blue-800 md:h-12" />}
								</motion.p>
							</div>
							<div className={`flex w-2/3 items-center justify-center  ${!profileUser?.data()?.bio && "hidden"}`}>
								<motion.p className="text-center text-lg text-gray-600">{profileUser?.data()?.bio}</motion.p>
							</div>
						</div>
					</div>
				</div>
				<Items items={items} />
				<a href="/" id="landing-button">
					<div className="mt-12 h-12 w-12 fill-accent-200 opacity-50 md:fixed md:left-1/2 md:bottom-2 md:mt-0 md:-translate-x-1/2">
						<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 499.18 458.13">
							<path d="M286.4,393.94" transform="translate(-50.41 -70.93)" />
							<path d="M44.45,383" transform="translate(-50.41 -70.93)" />
							<path d="M253.72,510.74" transform="translate(-50.41 -70.93)" />
							<g>
								<path
									d="M296.68,307.59q7.41,10.92,7.78,18.53A31.26,31.26,0,0,1,301,341.51a24.48,24.48,0,0,1-8.75,9.82q-5.83,3.82-14.78,3.67t-21.34-6.22a102.79,102.79,0,0,1-19.94-12.34,40.22,40.22,0,0,1-11.57-14.31A32.27,32.27,0,0,1,222,304.9q1-9.39,6.86-21.39l-75.1-36.68q-13,26.57-11.81,50.77a100.21,100.21,0,0,0,12.89,45,140,140,0,0,0,30.77,37.29,193.76,193.76,0,0,0,41.82,27.6,204.68,204.68,0,0,0,34.67,13.42,346.65,346.65,0,0,0-90.59,63.26,342.74,342.74,0,0,0-38.84,44.88c-7.73-7.3-102-99.17-78.57-223.78C67.27,235.39,114,170,219.72,133.68c-.66.23-.63,1.26-.94,1.89q-9.85,20.18-8.83,39.36a95.8,95.8,0,0,0,9.79,37.18A178.65,178.65,0,0,0,242.28,247a417.93,417.93,0,0,0,29.44,32.43Q289.27,296.7,296.68,307.59Z"
									transform="translate(-50.41 -70.93)"
								/>
								<path
									d="M522.91,144.18l20.76,60.39a108.48,108.48,0,0,1-67.33,137.84l-99.73,34.27q11.67-24.43,9.26-47.79-2.47-23.83-17.64-47.71T326.75,232.1q-12.54-12.3-21.49-22.86t-12.5-19.47q-3.58-8.93.24-16.71a22.44,22.44,0,0,1,10.09-10.29q6.78-3.48,16.73-2.84T342,166.54q12.57,6.14,18.72,14.1a29.43,29.43,0,0,1,6.52,17,38.22,38.22,0,0,1-4.2,18.48l74.7,36.49q13.86-28.37,9.73-55.61T424,146.84q-19.36-22.93-51.52-38.65a195.39,195.39,0,0,0-37.66-14l50.31-17.29A108.47,108.47,0,0,1,522.91,144.18Z"
									transform="translate(-50.41 -70.93)"
								/>
							</g>
						</svg>
					</div>
				</a>
			</div>

			<LoadingScreen loading={loading} />
		</div>
	);
}

const Items = ({ items }) => {
	// const rotation = 45;
	return (
		<div className="flex flex-row flex-wrap justify-center gap-3">
			{items.length > 0 ? (
				items.map((item, index) => (
					<motion.div
						initial={{ opacity: 0, rotate: -15 }}
						animate={{ opacity: 1, rotate: 0 }}
						transition={{ delay: 0.05 * index }}
						key={index}
					>
						<Item item={item} index={index} />
					</motion.div>
				))
			) : (
				<motion.div initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }}>
					<ItemButton loading />
				</motion.div>
			)}
		</div>
	);
};

const ItemButton = ({ onClick, title, icon, showIcon, showTitle, className, loading }) => {
	return (
		<div
			className={`${className} flex h-24 w-24 cursor-pointer flex-col items-center justify-center rounded-3xl border-[1px] border-white bg-white/60 shadow-smooth backdrop-blur-sm`}
			onClick={onClick}
		>
			{loading ? (
				<div className="h-1/3 w-1/3 animate-spin rounded-full border-t-4 border-gray-200"></div>
			) : (
				<>
					<p className={`${showTitle ? "visible" : "hidden"}`}>{title}</p>
					<div className={`${showIcon ? "visible" : "hidden"} flex items-center justify-center`}>
						<ion-icon name={icon} />
					</div>
				</>
			)}
		</div>
	);
};

const Item = ({ item, index }) => {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	let [isOpen, setIsOpen] = useState(false);
	let [isTextOpen, setIsTextOpen] = useState(false);
	const [effect, setEffect] = useState(false);

	function closeModal() {
		setIsOpen(false);
	}

	function openModal() {
		setIsOpen(true);
	}

	function closeTextModal() {
		setIsTextOpen(false);
	}

	function openTextModal() {
		setIsTextOpen(true);
	}
	return (
		<div>
			{item.entrys.length > 1 ? (
				<div>
					{/* <ItemButton
						onClick={openModal}
						title={item.name}
						showTitle={item.show_name}
						icon={item.icon}
						showIcon={item.show_icon}
					/> */}
					<SocialLinkButton
						onClick={openModal}
						title={item.name}
						showTitle={item.show_name}
						icon={item.icon}
						showIcon={item.show_icon}
						active={true}
					/>
					<Transition appear show={isOpen} as={Fragment}>
						<Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}>
							<Dialog.Overlay className="fixed inset-0 bg-black opacity-20" />
							<div className="min-h-screen px-4 text-center">
								<Transition.Child
									as={Fragment}
									enter="ease-out duration-300"
									enterFrom="opacity-0"
									enterTo="opacity-100"
									leave="ease-in duration-200"
									leaveFrom="opacity-100"
									leaveTo="opacity-0"
								>
									<Dialog.Overlay className="fixed inset-0" />
								</Transition.Child>

								<span className="inline-block h-screen align-middle" aria-hidden="true">
									&#8203;
								</span>
								<Transition.Child
									as={Fragment}
									enter="ease-out duration-300"
									enterFrom="opacity-0 scale-95"
									enterTo="opacity-100 scale-100"
									leave="ease-in duration-200"
									leaveFrom="opacity-100 scale-100"
									leaveTo="opacity-0 scale-95"
								>
									<div className="my-8 inline-block w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-5 text-left align-middle shadow-xl transition-all">
										<Dialog.Title as="h3" className="text-lg font-bold leading-6 text-black">
											<div
												container
												direction="row"
												justifyContent="space-between"
												alignItems="flex-end"
												className="flex flex-row items-end justify-between"
											>
												<div>{item.name}</div>
												<div>
													<button
														type="button"
														className="float-right inline-flex justify-center rounded-md border border-transparent bg-red-600 px-2 py-1 text-sm font-medium text-white hover:bg-red-700"
														onClick={closeModal}
													>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															className="h-6 w-6"
															fill="none"
															viewBox="0 0 24 24"
															stroke="currentColor"
														>
															<path
																strokeLinecap="round"
																strokeLinejoin="round"
																strokeWidth={2}
																d="M6 18L18 6M6 6l12 12"
															/>
														</svg>
													</button>
												</div>
											</div>
										</Dialog.Title>
										<div className="mt-2 mb-4 w-full border-b-2" />

										<div
											container
											direction="row"
											justifyContent="center"
											alignItems="center"
											spacing={2}
											className="flex flex-row flex-wrap items-center justify-center gap-4 text-black"
										>
											{item.entrys.map((link, index) => (
												<div key={index}>
													{link.link.includes("http") ? (
														<a
															href={link.link}
															target="_blank"
															rel="noreferrer"
															className="inline-flex cursor-pointer select-none justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-base font-medium text-blue-900 hover:bg-blue-200"
														>
															{link.name}
														</a>
													) : (
														<div
															className={`flex cursor-pointer flex-row items-center gap-2 rounded-md border-[1px] border-blue-900 bg-blue-300 bg-opacity-0 px-4 py-2 hover:bg-opacity-30 ${
																effect[index] && "animate-flash"
															}`}
															onClick={() => {
																copy(link.link);
																enqueueSnackbar(t("profile.snackbar.copied"), {
																	variant: "success",
																});
																setEffect({
																	...effect,
																	[index]: true,
																});
															}}
															onAnimationEnd={() =>
																setEffect({
																	...effect,
																	[index]: false,
																})
															}
														>
															<ion-icon name="copy-outline" size="small"></ion-icon>
															<p className="overflow-y-auto">{link.link}</p>
														</div>
													)}
												</div>
											))}
										</div>
									</div>
								</Transition.Child>
							</div>
						</Dialog>
					</Transition>
				</div>
			) : (
				<div>
					{item.entrys[0].link.includes("https://") ? (
						<a
							href={item.entrys[0].link.includes("https://") && item.entrys[0].link}
							target="_blank"
							rel="noopener noreferrer"
						>
							<SocialLinkButton
								title={item.name}
								showTitle={item.show_name}
								icon={item.icon}
								showIcon={item.show_icon}
								active={true}
							/>
						</a>
					) : (
						<SocialLinkButton
							onClick={openTextModal}
							title={item.name}
							showTitle={item.show_name}
							icon={item.icon}
							showIcon={item.show_icon}
							active={true}
						/>
					)}
					<Transition appear show={isTextOpen} as={Fragment}>
						<Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto text-black" onClose={closeTextModal}>
							<Dialog.Overlay className="fixed inset-0 bg-black opacity-20" />
							<div className="min-h-screen px-4 text-center">
								<Transition.Child
									as={Fragment}
									enter="ease-out duration-300"
									enterFrom="opacity-0"
									enterTo="opacity-100"
									leave="ease-in duration-200"
									leaveFrom="opacity-100"
									leaveTo="opacity-0"
								>
									<Dialog.Overlay className="fixed inset-0" />
								</Transition.Child>

								{/* This element is to trick the browser into centering the modal contents. */}
								<span className="inline-block h-screen align-middle" aria-hidden="true">
									&#8203;
								</span>
								<Transition.Child
									as={Fragment}
									enter="ease-out duration-300"
									enterFrom="opacity-0 scale-95"
									enterTo="opacity-100 scale-100"
									leave="ease-in duration-200"
									leaveFrom="opacity-100 scale-100"
									leaveTo="opacity-0 scale-95"
								>
									<div className="my-8 inline-block w-full max-w-md transform overflow-hidden rounded-2xl border-gray-800 bg-white p-5 text-left align-middle shadow-xl transition-all">
										<Dialog.Title as="h3" className="text-lg font-bold leading-6">
											<div
												container
												direction="row"
												justifyContent="space-between"
												alignItems="flex-end"
												className="flex flex-row items-end justify-between"
											>
												<p>{item.name}</p>
												<div>
													<button
														type="button"
														className="float-right inline-flex justify-center rounded-md border border-transparent bg-red-600 px-2 py-1 text-sm font-medium text-white hover:bg-red-700"
														onClick={closeTextModal}
													>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															className="h-6 w-6"
															fill="none"
															viewBox="0 0 24 24"
															stroke="currentColor"
														>
															<path
																strokeLinecap="round"
																strokeLinejoin="round"
																strokeWidth={2}
																d="M6 18L18 6M6 6l12 12"
															/>
														</svg>
													</button>
												</div>
											</div>
										</Dialog.Title>
										<div className="mt-2 mb-4 w-full border-b-2" />
										<div
											className={`flex cursor-pointer flex-row items-center gap-2 rounded-md border-blue-900 bg-blue-300 bg-opacity-0 p-1 hover:border-[1px] hover:bg-opacity-30 ${
												effect && "animate-flash"
											}`}
											onClick={() => {
												copy(item.entrys[0].link);
												enqueueSnackbar(t("profile.snackbar.copied"), {
													variant: "success",
												});
												setEffect(true);
											}}
											onAnimationEnd={() => setEffect(false)}
										>
											<ion-icon name="copy-outline" size="small"></ion-icon>
											<p className="overflow-y-auto">{item.entrys[0].link}</p>
										</div>
									</div>
								</Transition.Child>
							</div>
						</Dialog>
					</Transition>
				</div>
			)}
		</div>
	);
};

import React from "react";

function Switch({ id, label, checked, onChange, className }) {
	return (
		<div id={id}>
            <div className="pointer-events-none select-none">{label}</div>
			<div onClick={() => onChange(!checked)} className={`h-[24px] w-12 cursor-pointer rounded-full bg-gray-300 ${className}`}>
				<div
					className={`${
						checked ? "translate-x-[100%] border-accent-100 bg-accent-200" : "border-white bg-gray-200"
					} h-full w-1/2 rounded-full border-[1px] duration-150`}
				/>
			</div>
		</div>
	);
}

export default Switch;

let currentPalette = {};

const calculateColorPalette = (color, predefined = true) => {
	const predefinedColors = [
		"#d42d2d",
		"#c6178d",
		"#9625ca",
		"#3f18ca",
		"#1c48d2",
		"#1674bc",
		"#2ac5c8",
		"#1bc44b",
		"#6abe14",
		"#dad941",
		"#e1952e",
		"#d05d13",
	];

	const root = document.documentElement;
	let mainColor = color ? color : getRandomColor(predefinedColors, predefined);

	if (mainColor.startsWith("var")) {
		mainColor = getComputedStyle(document.body).getPropertyValue(mainColor.split("var(")[1].split(")")[0]).trim();
	}

	const lighterColor = shadeColor(mainColor, 50);
	const darkerColor = shadeColor(mainColor, -50);

	const tmpLighters = blendColors(hexToRgb(lighterColor), hexToRgb(mainColor));
	const tmpDarkers = blendColors(hexToRgb(mainColor), hexToRgb(darkerColor));

	let lighters = [];
	let darkers = [];

	tmpLighters.map((color, index) => (lighters[index] = rgbToHex(color)));
	tmpDarkers.map((color, index) => (darkers[index] = rgbToHex(color)));

	lighters.pop();
	darkers.shift();

	root.style.setProperty("--accent-100", lighters[0]);
	root.style.setProperty("--accent-125", lighters[1]);
	root.style.setProperty("--accent-150", lighters[2]);
	root.style.setProperty("--accent-175", lighters[3]);
	root.style.setProperty("--accent-200", mainColor);
	root.style.setProperty("--accent-225", darkers[0]);
	root.style.setProperty("--accent-250", darkers[1]);
	root.style.setProperty("--accent-275", darkers[2]);
	root.style.setProperty("--accent-300", darkers[3]);

	const rgb = hexToRgb(mainColor);

	let contrast = Math.round((parseInt(rgb.r) * 299 + parseInt(rgb.g) * 587 + parseInt(rgb.b) * 114) / 1000);

	const contrastHex = contrast > 125 ? "#000000" : "#FFFFFF";

	root.style.setProperty("--text", contrastHex);

	const colors = lighters.concat(darkers).concat(mainColor).concat(contrastHex);

	colors.forEach((color, index) => {
		const name = index === colors.length - 1 ? "contrast" : 100 + 25 * index;
		currentPalette[String(name)] = color;
	});
};

const getColorPalette = (value) => {
	return currentPalette;
};

const getColorFromString = (input) => {
	let hash = 0;
	for (let i = 0; i < input.length; i++) {
		hash = input.charCodeAt(i) + ((hash << 5) - hash);
	}
	let color = "#";
	for (let i = 0; i < 3; i++) {
		let value = (hash >> (i * 8)) & 0xff;
		color += ("00" + value.toString(16)).substr(-2);
	}
	return color;
};

function getRandomColor(predefinedColors, predefined) {
	return predefined
		? predefinedColors[Math.floor(Math.random() * predefinedColors.length)]
		: "#" + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0");
}

const shadeColor = (color, percent) => {
	let r = parseInt(color.substring(1, 3), 16);
	let g = parseInt(color.substring(3, 5), 16);
	let b = parseInt(color.substring(5, 7), 16);

	r = parseInt((r * (100 + percent)) / 100);
	g = parseInt((g * (100 + percent)) / 100);
	b = parseInt((b * (100 + percent)) / 100);

	r = r < 255 ? r : 255;
	g = g < 255 ? g : 255;
	b = b < 255 ? b : 255;

	let rr = r.toString(16).length === 1 ? "0" + r.toString(16) : r.toString(16);
	let gg = g.toString(16).length === 1 ? "0" + g.toString(16) : g.toString(16);
	let bb = b.toString(16).length === 1 ? "0" + b.toString(16) : b.toString(16);
	return `#${rr}${gg}${bb}`;
};

const blendColors = (color1, color2) => {
	const steps = 4;
	let count = steps + 1;

	let colors = [];
	for (let i = 0; i < count; i++) {
		let r = color1.r + ((color2.r - color1.r) / steps) * i;
		let g = color1.g + ((color2.g - color1.g) / steps) * i;
		let b = color1.b + ((color2.b - color1.b) / steps) * i;
		colors[i] = {
			r: parseInt(r),
			g: parseInt(g),
			b: parseInt(b),
		};
	}
	return colors;
};

const hexToRgb = (hex) => {
	let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result
		? {
				r: parseInt(result[1], 16),
				g: parseInt(result[2], 16),
				b: parseInt(result[3], 16),
		  }
		: null;
};

const rgbToHex = (color) => {
	const { r, g, b } = color;
	return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
};

export { calculateColorPalette, getColorFromString, shadeColor, getColorPalette };

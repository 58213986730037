import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import useFirebase from "./hooks/useFirebase";

import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Settings from "./pages/Settings";
import Initialize from "./pages/Initialize";
import Profile from "./pages/Profile";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Imprint from "./pages/Imprint";

function Router() {
	const {
		setUser,
		auth,
		onAuthStateChanged,
		setProfilePictureUrl,
		getDownloadURL,
		ref,
		getStorage,
		user,
		analytics,
		setUserId,
		setItems,
		getDoc,
		doc,
		firestore,
		setUserProfile,
		onSnapshot,
		collection,
		userProfile,
		profilePictureUrl,
	} = useFirebase();
	const storage = getStorage();

	useEffect(() => {
		onAuthStateChanged(auth, (user) => {
			setUser(user);
			user && setUserId(analytics, user.uid);
		});
		// eslint-disable-next-line
	}, []);

	// useEffect(() => {
	//     const fetch = async () => {
	//         await fetchProfilePictureUrl();
	//         // await fetchData();
	//         await fetchUserProfile();
	//     }

	// 	if (user !== null && user.uid !== undefined) {
	// 		fetch();
	// 	}
	// 	// eslint-disable-next-line
	// }, [user]);

	useEffect(() => {
		fetchProfilePictureUrl();
		// eslint-disable-next-line
	}, [user]);

	useEffect(() => {
		fetchUserProfile();
		// eslint-disable-next-line
	}, [profilePictureUrl]);

	useEffect(() => {
		fetchData();
		// eslint-disable-next-line
	}, [userProfile]);

	async function fetchProfilePictureUrl() {
		return new Promise(async (resolve, reject) => {
			if (user !== null && user.uid !== undefined) {
				getDownloadURL(ref(storage, `users/${user.uid}/profilePicture.png`))
					.then((url) => {
						setProfilePictureUrl(url);
						resolve();
					})
					.catch((error) => {
						setProfilePictureUrl("logo512.png");
						resolve();
					});
			}
		});
	}

	async function fetchUserProfile() {
		return new Promise(async (resolve, reject) => {
			if (user !== null && user.uid !== undefined) {
				try {
					const userSnap = await getDoc(doc(firestore, "users", user.uid));
					setUserProfile(userSnap);
					resolve();
				} catch (e) {
					reject();
				}
			}
		});
	}

	async function fetchData() {
		return new Promise(async (resolve, reject) => {
			if (user !== null && user.uid !== undefined) {
				try {
					const unsubscribe = onSnapshot(collection(firestore, "users", user.uid, "items"), async (snap) => {
						let arr = [];
						await snap.forEach((doc) => {
							arr.push({ ...doc.data(), id: doc.id });
						});
						setItems([...arr]);
					});
					resolve(unsubscribe);
				} catch (e) {
					console.log(e);
					reject();
				}
			}
		});
	}

	return (
		<BrowserRouter>
			<Routes>
				<Route path="/">
					<Route index element={<Home />} />
					<Route path="settings" element={<Settings />} />
					<Route path="initialize" element={<Initialize />} />
					<Route path="dashboard" element={<Dashboard />} />
					<Route path="login" element={<Login />} />
					<Route path=":usernameParam" element={<Profile />} />
					<Route path="privacy-policy" element={<PrivacyPolicy />} />
					<Route path="imprint" element={<Imprint />} />
				</Route>
			</Routes>
		</BrowserRouter>
	);
}

export default Router;

import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import ReactCountryFlag from "react-country-flag";

import Divider from "./Divider";

const languages = {
	en: {
		name: "English",
		flag: "gb",
	},
	de: {
		name: "Deutsch",
		flag: "de",
	},
	fr: {
		name: "Français",
		flag: "fr",
	},
	es: {
		name: "Español",
		flag: "es",
	},
	it: {
		name: "Italiano",
		flag: "it",
	},
	pt: {
		name: "Português",
		flag: "pt",
	},
	ru: {
		name: "Русский",
		flag: "ru",
	},
	zh: {
		name: "中文",
		flag: "cn",
	},
};

export default function LanguagePicker() {
	const { i18n } = useTranslation();
	const [open, setOpen] = useState(false);
	const wrapperRef = useRef(null);

	useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside(event) {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
				setOpen(false);
			}
		}

		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [wrapperRef]);

	return (
		<div ref={wrapperRef}>
			<div
				className="hidden cursor-pointer flex-row items-center justify-center gap-2 rounded-lg border-2 border-gray-400 p-1 hover:bg-gray-100 md:flex"
				onClick={() => setOpen(!open)}
			>
				<ReactCountryFlag countryCode={languages[i18n.resolvedLanguage].flag} svg />
				<div>{languages[i18n.resolvedLanguage].name}</div>
			</div>
			<div
				className="flex cursor-pointer rounded-lg border-2 border-gray-400 p-1 md:hidden"
				onClick={() => setOpen(!open)}
			>
				<ReactCountryFlag className="w-5" countryCode={languages[i18n.resolvedLanguage].flag} svg />
			</div>
			<div
				className={`absolute h-auto w-32 -translate-x-24 translate-y-2 overflow-y-auto rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 md:translate-y-1 md:translate-x-0 ${
					!open && "hidden"
				}`}
			>
				{Object.keys(languages).map((code, i) => (
					<div key={i}>
						<div
							key={code}
							className={`flex cursor-pointer flex-row items-center justify-between p-1 px-3 ${
								code === i18n.resolvedLanguage ? "bg-gray-200" : "bg-white"
							} hover:bg-gray-200`}
							onClick={() => {
								i18n.changeLanguage(code);
							}}
						>
							<div>
								<ReactCountryFlag className="w-5" countryCode={languages[code].flag} svg />
							</div>
							<div>{languages[code].name}</div>
						</div>
						{i !== Object.keys(languages).length - 1 && <Divider />}
					</div>
				))}
			</div>
		</div>
	);
}

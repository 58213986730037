import React, { useState, useEffect } from "react";

import { shadeColor } from "../assets/ColorCalculation";
import { motion } from "framer-motion";

// import useCursor from "../hooks/useCursor";
// import { useLocomotiveScroll } from "../hooks/useLocomotive";

function SocialsAnimation() {
	const [socials, setSocials] = useState([
		{
			icon: "logo-instagram",
			color: "#d62976",
			step: 0,
			image:
				"https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=688&q=80",
		},
		{
			icon: "logo-twitter",
			color: "#1cc4f7",
			step: 0,
			image:
				"https://images.unsplash.com/photo-1500048993953-d23a436266cf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80",
		},
		{
			icon: "logo-reddit",
			color: "#f24100",
			step: 0,
			image:
				"https://images.unsplash.com/photo-1599566150163-29194dcaad36?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
		},
		{
			icon: "logo-tiktok",
			color: "#120f0f",
			step: 0,
			image:
				"https://images.unsplash.com/photo-1544723795-3fb6469f5b39?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=689&q=80",
		},
		{
			icon: "logo-twitch",
			color: "#613f9f",
			step: 0,
			image:
				"https://images.unsplash.com/photo-1529626455594-4ff0802cfb7e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
		},
		{
			icon: "logo-youtube",
			color: "#bd332d",
			step: 0,
			image:
				"https://images.unsplash.com/photo-1593104547489-5cfb3839a3b5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1153&q=80",
		},
	]);

	// const parent = useRef();
	// const [parentInfo, setParentInfo] = useState({ x: 0, y: 0, width: 0, height: 0, centerX: 0, centerY: 0 });
	// const [mouseMovement, setMouseMovement] = useState({ x: 0, y: 0 });
	// const updateParentInfo = () => {
	// 	const rect = parent.current.getBoundingClientRect();
	// 	setParentInfo({
	// 		x: rect.left,
	// 		y: rect.top,
	// 		width: rect.width,
	// 		height: rect.height,
	// 		centerX: rect.left + rect.width / 2,
	// 		centerY: rect.top + rect.height / 2,
	// 	});
	// };
	// const { scrollX, scrollY } = useLocomotiveScroll(locomotive.current, () => {
	// 	updateParentInfo();
	// });
	// const { mouseX, mouseY } = useCursor();
	// useEffect(() => {
	// 	if (parent.current) {
	// 		updateParentInfo();
	// 	}
	// }, [parent.current]);

	// useEffect(() => {
	// 	const xDiff = parentInfo.centerX - mouseX;
	// 	const yDiff = parentInfo.centerY - mouseY;

	// 	setMouseMovement({ x: xDiff, y: yDiff });
	// }, [mouseX, mouseY]);

	const spacing = 20;
	const delay = 0.025;
	// const decrease = 40;

	useEffect(() => {
		let tmpSocials = [...socials];
		tmpSocials.forEach((social, index) => {
			social.step = index;
		});
		setSocials(tmpSocials);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const i = setInterval(() => {
			let tmpSocials = [...socials];
			tmpSocials.forEach((social, index) => {
				if (social.step === socials.length - 1) {
					social.step = 0;
				} else {
					social.step += 1;
				}
			});
			setSocials(tmpSocials);
		}, 1000);
		return () => {
			clearInterval(i);
		};
		// eslint-disable-next-line
	}, []);

	return (
		// <div ref={parent} className="relative aspect-square w-48 md:w-64">
		<div className="relative aspect-square w-48 md:w-64">
			{socials.map((social, index) => (
				<div key={index} style={{ zIndex: social.step }} className="absolute h-full w-full">
					<div className="h-full w-full">
						<motion.div
							style={{
								width: "100%",
								height: "100%",
								transform: `translate(calc(4rem + ${spacing * -social.step}px), calc(-3.5rem + ${
									spacing * social.step
								}px))`,
								transition: `transform .6s ease ${delay * social.step}s`,
							}}
							// style={{
							// 	width: "100%",
							// 	height: "100%",
							// }}
							// animate={{
							// 	x: (mouseMovement.x / decrease) * -social.step,
							// 	y: (mouseMovement.y / decrease) * -social.step,
							// }}
						>
							<SocialCard social={social} />
						</motion.div>
					</div>
				</div>
			))}
		</div>
	);
}

function SocialCard({ social }) {
	return (
		<div
			style={{
				background: social.color,
				border: `solid 4px ${shadeColor(social.color, 20)}`,
			}}
			className={`flex h-full w-full items-center justify-center rounded-3xl shadow-lg`}
		>
			<div className="scale-150 brightness-0 invert">
				<ion-icon name={social.icon} />
			</div>
		</div>
	);
}

export default SocialsAnimation;

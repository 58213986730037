import React, { createContext, useState } from "react";

import { initializeApp, getApps, getApp } from "@firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "@firebase/app-check";
import { getAnalytics, setUserId, logEvent } from "firebase/analytics";
import {
	getAuth,
	GoogleAuthProvider,
	signInWithPopup,
	signOut,
	onAuthStateChanged,
	signInWithRedirect,
} from "@firebase/auth";
import { getFirestore, collection, onSnapshot, getDoc, doc, query, where, getDocs } from "firebase/firestore";
import { getFunctions, httpsCallable } from "@firebase/functions";
import { getStorage, ref, getDownloadURL, uploadString, uploadBytes } from "firebase/storage";

const firebaseConfig = {
	apiKey: "AIzaSyBiBypvcwVKvlGIQH3rv-LiK8C9y2gAWrQ",
	authDomain: "social-share-90d8f.firebaseapp.com",
	projectId: "social-share-90d8f",
	storageBucket: "social-share-90d8f.appspot.com",
	messagingSenderId: "930423593368",
	appId: "1:930423593368:web:9ee2171b8d6928bd89f3ed",
	measurementId: "G-M292PNX213",
};

const firebase = getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();
const analytics = getAnalytics(firebase);
const firestore = getFirestore(firebase);
const auth = getAuth(firebase);
const provider = new GoogleAuthProvider();
const functions = getFunctions(firebase);

initializeAppCheck(getApp(), {
	provider: new ReCaptchaV3Provider("6LeF71AeAAAAAI3woOKkWEHwQeYbkrThx-KGtUmU"),

	// Optional argument. If true, the SDK automatically refreshes App Check
	// tokens as needed.
	isTokenAutoRefreshEnabled: true,
});

const FirebaseContext = createContext({
	firestore: firestore,
	auth: auth,
	user: "",
	provider: provider,
	functions: functions,
	analytics: analytics,
	profilePictureUrl: "logo512.png",
	userProfile: undefined,
	items: [],
	doc: doc,
	httpsCallable: httpsCallable,
	signInWithPopup: signInWithPopup,
	signInWithRedirect: signInWithRedirect,
	collection: collection,
	query: query,
	where: where,
	onSnapshot: onSnapshot,
	signOut: signOut,
	onAuthStateChanged: onAuthStateChanged,
	getDoc: getDoc,
	getDocs: getDocs,
	getStorage: getStorage,
	getDownloadURL: getDownloadURL,
	ref: ref,
	uploadString: uploadString,
	uploadBytes: uploadBytes,
	setUserId: setUserId,
	logEvent: logEvent,
	setFirebase: () => {},
	setUser: () => {},
	setProfilePictureUrl: () => {},
	setItems: () => {},
	setUserProfile: () => {},
});

const FirebaseProvider = ({ children }) => {
	const [firebaseState, setFirebaseState] = useState({
		firestore: firestore,
		auth: auth,
		user: "",
		provider: provider,
		functions: functions,
		analytics: analytics,
		profilePictureUrl: "logo512.png",
		userProfile: undefined,
		items: [],
		doc: doc,
		httpsCallable: httpsCallable,
		signInWithPopup: signInWithPopup,
		signInWithRedirect: signInWithRedirect,
		collection: collection,
		query: query,
		where: where,
		onSnapshot: onSnapshot,
		signOut: signOut,
		onAuthStateChanged: onAuthStateChanged,
		getDoc: getDoc,
		getDocs: getDocs,
		getStorage: getStorage,
		uploadString: uploadString,
		uploadBytes: uploadBytes,
		setUserId: setUserId,
		logEvent: logEvent,
		ref: ref,
		getDownloadURL: getDownloadURL,
	});

	const setUser = (user) => {
		setFirebaseState({
			...firebaseState,
			user,
		});
	};

	const setProfilePictureUrl = (profilePictureUrl) => {
		setFirebaseState({
			...firebaseState,
			profilePictureUrl,
		});
	};

	const setItems = (items) => {
		setFirebaseState({
			...firebaseState,
			items,
		});
	};

	const setUserProfile = (userProfile) => {
		setFirebaseState({
			...firebaseState,
			userProfile,
		});
	};

	return (
		<FirebaseContext.Provider
			value={{
				...firebaseState,
				setUser,
				setProfilePictureUrl,
				setItems,
				setUserProfile,
			}}
		>
			{children}
		</FirebaseContext.Provider>
	);
};

export { FirebaseContext, FirebaseProvider };

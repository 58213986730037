import React from "react";

import { SnackbarProvider } from "notistack";
import { FirebaseProvider } from "./contexts/FirebaseContext";

import Router from "./Router";
import CookiePopup from "./components/CookiePopup";
import { TourProvider } from "@reactour/tour";
import { useTranslation } from "react-i18next";

function App() {
	const { t } = useTranslation();
	const steps = [
		{
			selector: "#tour-toggle-item",
			content: t("dashboard.tour.itemeditor.toggleitem"),
		},
		{
			selector: "#tour-choose-item-image",
			content: t("dashboard.tour.itemeditor.selectitemimage"),
		},
		{
			selector: "#tour-toggle-item-image",
			content: t("dashboard.tour.itemeditor.toggleitemimage"),
		},
		{
			selector: "#tour-choose-item-title",
			content: t("dashboard.tour.itemeditor.chooseitemtitle"),
		},
		{
			selector: "#tour-toggle-item-title",
			content: t("dashboard.tour.itemeditor.toggleitemtitle"),
		},
		{
			selector: "#tour-delete-item",
			content: t("dashboard.tour.itemeditor.deleteitem"),
		},
		{
			selector: "#tour-item-content-title",
			content: t("dashboard.tour.itemeditor.itemcontenttitle"),
		},
		{
			selector: "#tour-item-content-link",
			content: t("dashboard.tour.itemeditor.itemcontentlink"),
		},
		{
			selector: "#tour-item-delete-content",
			content: t("dashboard.tour.itemeditor.itemdeletecontent"),
		},
		{
			selector: "#tour-item-add-content",
			content: t("dashboard.tour.itemeditor.itemaddcontent"),
		},
	];

	return (
		<FirebaseProvider>
			<SnackbarProvider
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
			>
				<TourProvider
					badgeContent={({ totalSteps, currentStep }) => `${currentStep + 1}/${totalSteps}`}
					onClickMask={({ setCurrentStep, currentStep, setIsOpen }) => {
						if (currentStep === steps.length - 1) {
							setIsOpen(false);
						}
						setCurrentStep((s) => (s === steps.length - 1 ? 0 : s + 1));
					}}
					disableInteraction
					disableFocusLock
					steps={steps}
				>
					<Router />
				</TourProvider>
				<CookiePopup />
			</SnackbarProvider>
		</FirebaseProvider>
	);
}

export default App;

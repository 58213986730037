import React from "react";

function Footer({ links }) {
	return (
		<div className="flex h-52 w-screen flex-col items-center justify-between border-t-[1px] border-neutral-300 bg-neutral-50 p-4">
			<div className="flex w-full flex-1 flex-row items-center justify-around">
				<div className="flex w-44 flex-col gap-2">
					<a href="/" className="flex cursor-pointer flex-row items-center gap-1">
						<img src="logo512.png" alt="logo" className="w-8" />
						<span className="font-bold">Sharrings</span>
					</a>
					<div className="text-sm text-neutral-400">
						<span>
							Protected by reCAPTCHA, the{" "}
							<a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer" className="underline">
								Google Privacy Policy
							</a>{" "}
							and the{" "}
							<a href="https://policies.google.com/terms" target="_blank" rel="noreferrer" className="underline">
								Terms of Service
							</a>{" "}
							apply.
						</span>
					</div>
				</div>
				<div className="flex flex-col gap-1">
					<a href="/privacy-policy" className="cursor-pointer">
						Privacy Policy
					</a>
					<a href="/imprint" className="cursor-pointer">
						Imprint
					</a>
					<a
						href="https://github.com/Hashico-de/sharrings-bug-reports/issues/new?assignees=&labels=&template=bug_report.md&title="
						target="_blank"
						rel="noreferrer"
						className="cursor-pointer"
					>
						Bug Report
					</a>
					{links?.map((link) => (
						<a href={link.url} className="cursor-pointer" key={link.url}>
							{link.text}
						</a>
					))}
					<div className="flex items-center justify-center rounded-lg bg-neutral-800 p-2 md:hidden">
						<img src="made_eu.png" className="h-8" />
					</div>
				</div>
				<div className="hidden flex-col gap-2 md:flex">
					<div className=" flex w-fit items-center justify-center rounded-lg bg-neutral-800 p-2">
						<img src="MadeInEu.svg" className="h-8" />
					</div>
					<a
						href="https://www.producthunt.com/posts/sharrings?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-sharrings"
						target="_blank"
					>
						<img
							src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=334023&theme=light"
							alt="Sharrings - All&#0032;your&#0032;socials&#0032;at&#0032;one&#0032;place&#0046; | Product Hunt"
							style={{
								width: 250,
								height: 54,
							}}
							width="250"
							height="54"
						/>
					</a>
				</div>
			</div>
			<p className="text-neutral-500">© {new Date().getFullYear()} Hashicode, All rights reserved.</p>
		</div>
	);
}

export default Footer;

const SocialButton = ({ children, href, onClick }) => {
	return (
		<a
			className="flex w-full max-w-lg cursor-pointer items-center justify-center gap-4 rounded-md border-2 border-slate-600 bg-white py-2 duration-200 hover:border-accent-200 hover:bg-slate-100"
			onClick={onClick}
			target="_blank"
			rel="noopener noreferrer"
			href={href}
		>
			{children}
		</a>
	);
};

export default SocialButton;

import React from "react";
import { motion } from "framer-motion";
import { Line1, Line2, Line3 } from "../assets/images/lines/Lines";

function Lines({ className }) {
	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			className={`${className} pointer-events-none fixed inset-0 -left-1/2 -z-50 h-screen w-screen md:left-0`}
		>
			<motion.div
				className="absolute left-1/4 h-full w-full"
				initial={{
					x: -200,
					rotate: -5,
				}}
				animate={{
					rotate: 5,
				}}
				transition={{
					ease: "easeInOut",
					duration: 10,
					delay: 6,
					repeat: Infinity,
					repeatType: "reverse",
				}}
			>
				<Line1 className="scale-110 opacity-20" />
			</motion.div>
			<motion.div
				className="absolute -left-1/3 h-full w-full"
				initial={{
					x: -50,
					y: -20,
					rotate: -10,
				}}
				animate={{
					x: -10,
					y: 20,
					rotate: -20,
				}}
				transition={{
					ease: "easeInOut",
					duration: 10,
					repeat: Infinity,
					repeatType: "reverse",
				}}
			>
				<Line2 duration="2" className="scale-110 opacity-10" />
			</motion.div>
			<motion.div
				className="absolute -left-1/4 h-full w-full"
				initial={{
					x: -20,
					rotate: -10,
				}}
				animate={{
					x: 20,
					rotate: 10,
				}}
				transition={{
					ease: "easeInOut",
					duration: 20,
					delay: 1,
					repeat: Infinity,
					repeatType: "reverse",
				}}
			>
				<Line3 className="scale-110 opacity-30" />
			</motion.div>
		</motion.div>
	);
}

export default Lines;
